@font-face {
  font-family: 'OpenSans-Regular';
  font-display: swap;
  src: local('OpenSans Regular'), local('OpenSans-Regular'), url('/assets/fonts/OpenSans/OpenSans-Regular.woff2') format('woff2'), url('/assets/fonts/OpenSans/OpenSans-Regular.woff') format('woff');
}
@font-face {
  font-family: 'OpenSans-Semibold';
  font-display: swap;
  src: local('OpenSans Semibold'), local('OpenSans-Semibold'), url('/assets/fonts/OpenSans/OpenSans-Semibold.woff2') format('woff2'), url('/assets/fonts/OpenSans/OpenSans-Semibold.woff') format('woff');
}
@font-face {
  font-family: 'Oswald-Medium';
  font-display: swap;
  src: local('Oswald Medium'), local('Oswald-Medium'), url('/assets/fonts/Oswald/Oswald-Medium.woff2') format('woff2'), url('/assets/fonts/Oswald/Oswald-Medium.woff') format('woff');
}
abbr,
acronym,
address,
applet,
b,
big,
blockquote,
body,
caption,
center,
cite,
code,
dd,
del,
dfn,
div,
dl,
dt,
em,
fieldset,
font,
form,
h1,
h2,
h3,
h4,
h5,
h6,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
object,
ol,
p,
pre,
q,
s,
samp,
small,
span,
strike,
strong,
sub,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
tr,
tt,
u,
ul,
var {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}
article,
aside,
div,
footer,
header,
main,
nav,
section {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
img {
  border: none !important;
}
button,
button:focus,
input,
textarea {
  -webkit-box-shadow: none!important;
          box-shadow: none!important;
  border-radius: 0!important;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: none;
  outline: 0;
}
textarea {
  resize: none;
}
a,
a:active,
a:link,
a:visited {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  text-decoration: none;
  outline: 0;
}
.none {
  display: none;
}
.block {
  display: block;
}
.tal {
  text-align: left;
}
.tac {
  text-align: center;
}
.tar {
  text-align: right;
}
.ibt {
  display: inline-block;
  vertical-align: top;
}
.ibm {
  display: inline-block;
  vertical-align: middle;
}
.ibb {
  display: inline-block;
  vertical-align: bottom;
}
.w100 {
  width: 100%;
}
.h100 {
  height: 100%;
}
.abs {
  position: absolute;
}
.fixed {
  position: fixed;
}
.rel {
  position: relative;
}
.contain {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.pointer {
  cursor: pointer;
}
.button {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ui-link {
  cursor: pointer;
  display: inline;
  border-bottom: 1px solid #315191;
  color: #315191;
}
.ui-link:hover {
  border-bottom: 1px solid transparent;
  color: #3B7DFF;
}
.ui-tabs__item {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  padding: 15px 20px;
  border-radius: 3px;
  color: #315191;
}
@media (max-width: 1023px) {
  .ui-tabs__item {
    padding: 15px 30px;
  }
}
@media (max-width: 767px) {
  .ui-tabs__item {
    font-size: 14px;
    padding: 15px 20px;
  }
}
.ui-tabs__item_active {
  background-image: url("/assets/images/pattern-2.svg");
  color: #666F81;
  background-color: #DDE4F2;
}
.ui-tabs__item_active .ui-tabs__item-text {
  border-bottom: 1px solid transparent;
}
.ui-tabs__item_active:hover .ui-tabs__item-text {
  color: #666F81 !important;
}
.ui-tabs__item:hover .ui-tabs__item-text {
  color: #3B7DFF;
  border-bottom: 1px solid transparent;
}
.ui-tabs__item-text {
  display: inline;
  border-bottom: 1px solid #315191;
}
@media (max-width: 767px) {
  .ui-tabs__item-text {
    white-space: nowrap;
  }
}
.ui-link-youtube {
  display: inline-block;
  vertical-align: middle;
  padding-left: 40px;
  color: white;
  position: relative;
}
.ui-link-youtube::before {
  background-image: url("/assets/images/youtube-red.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 29px;
}
.ui-link-youtube__text {
  border-bottom: 1px solid #E62117;
}
.ui-link-youtube:hover .ui-link-youtube__text {
  border-bottom: 1px solid transparent;
}
.ui-button {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  background-image: url("/assets/images/pattern-3.svg");
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  color: white;
  background-color: #3B7DFF;
  font-family: 'OpenSans-Regular';
  font-size: 16px;
  border-radius: 3px !important;
  -webkit-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.ui-button:hover {
  background-image: url("/assets/images/pattern-4.svg");
  background-color: #315191;
}
.ui-button:focus {
  border-radius: 3px !important;
}
.ui-navigation-button {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("/assets/images/arrow-left.svg");
  cursor: pointer;
  height: 25px;
  width: 16px;
}
@media (max-width: 767px) {
  .ui-navigation-button {
    width: 10px;
    height: 17px;
  }
}
.ui-navigation-button:hover {
  background-image: url("/assets/images/arrow-left-light.svg");
}
.ui-navigation-button_next {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.ui-contact {
  margin-bottom: 15px;
  font-size: 0;
}
.ui-contact:last-child {
  margin-bottom: 0;
}
.ui-contact_address .ui-contact__icon {
  background-image: url("/assets/images/pin-gray.svg");
}
.ui-contact_phone .ui-contact__icon {
  background-image: url("/assets/images/phone-gray.svg");
}
.ui-contact_email .ui-contact__icon {
  background-image: url("/assets/images/email-gray.svg");
}
.ui-contact_site .ui-contact__icon {
  background-image: url("/assets/images/home-gray.svg");
}
.ui-contact__icon {
  display: inline-block;
  vertical-align: top;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  width: 20px;
  height: 20px;
  position: relative;
  bottom: -2px;
}
.ui-contact__content {
  display: inline-block;
  vertical-align: top;
  padding: 0 0 0 15px;
  width: calc(100% - 20px);
}
.ui-contact__elem {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 5px;
  font-size: 16px;
  color: #666F81;
}
@media (max-width: 767px) {
  .ui-contact__elem {
    font-size: 14px;
  }
}
.ui-contact__elem:last-child {
  margin-bottom: 0;
}
.ui-contact__elem:hover {
  text-decoration: underline;
}
.header {
  top: 0;
  right: 0;
  bottom: auto;
  left: 0;
  position: absolute;
  z-index: 10;
}
@media (max-width: 767px) {
  .header {
    background-image: url("/assets/images/pattern.svg");
    height: 86px;
    background-color: #383C45;
  }
}
.header::after {
  content: '';
  width: calc((100vw - 1110px) / 2);
  background: white;
  position: absolute;
  top: 53px;
  bottom: 0;
  right: 0;
  z-index: 0;
}
@media screen and (max-width: 1200px) {
  .header::after {
    width: 40px;
  }
}
@media (max-width: 767px) {
  .header::after {
    display: none;
  }
}
.header__menu-button {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("/assets/images/burder.svg");
  display: none;
  width: 28px;
  height: 20px;
  position: absolute;
  top: 33px;
  left: 20px;
}
@media (max-width: 767px) {
  .header__menu-button {
    display: block;
  }
}
.header__holder {
  position: relative;
  z-index: 1;
}
@media (max-width: 767px) {
  .header__holder {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    background: white;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    opacity: 0;
    visibility: hidden;
    display: none;
  }
}
.header__holder_display {
  display: block;
}
.header__holder_show {
  opacity: 1;
  visibility: visible;
}
.header__button-close {
  display: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("/assets/images/close-blue.svg");
  width: 20px;
  height: 20px;
  margin: 32px 0 32px 30px;
}
@media (max-width: 767px) {
  .header__button-close {
    display: block;
  }
}
.header__top {
  background-image: url("/assets/images/pattern.svg");
  height: 53px;
  background-color: #9BA5B9;
  padding: 15px 0;
  position: relative;
}
@media (max-width: 767px) {
  .header__top {
    background: none;
  }
}
.header__link {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 50px;
  font-size: 14px;
  color: white;
  border-bottom: 1px solid transparent;
}
@media screen and (min-width: 992px) {
  .header__link:hover {
    border-bottom: 1px solid transparent !important;
  }
}
@media (max-width: 1023px) {
  .header__link {
    margin-right: 40px;
  }
}
@media (max-width: 767px) {
  .header__link {
    display: none;
  }
}
@media (max-width: 767px) {
  .header__link_mobile {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 20px;
  }
  .header__link_mobile:last-child {
    margin-bottom: 0;
  }
}
.header__link_email:hover {
  text-decoration: underline;
}
.header__link_phone::before {
  display: inline-block;
  vertical-align: middle;
  background-image: url("/assets/images/phone.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin-right: 10px;
  content: '';
  width: 20px;
  height: 20px;
  position: relative;
  top: -2px;
}
.header__link_phone:hover {
  text-decoration: underline;
}
.header__link_callback {
  border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
}
.header__link_callback:hover {
  border-bottom: 1px dashed rgba(255, 255, 255, 0);
}
.header__link_youtube {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  position: relative;
}
.header__link_youtube:hover {
  border-bottom: 1px dashed rgba(255, 255, 255, 0);
}
.header__link_youtube::after {
  background-image: url("/assets/images/youtube.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  top: 1px;
  left: calc(100% + 14px);
  display: inline-block;
  vertical-align: middle;
  content: '';
  width: 30px;
  height: 20px;
}
@media screen and (min-width: 992px) {
  .header__link_youtube:hover::after {
    background-image: url("/assets/images/youtube-red.svg");
  }
}
.header__logotype {
  background-image: url("/assets/images/logotype.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  vertical-align: middle;
  width: 152px;
  height: 50px;
  margin-right: 40px;
}
@media (max-width: 1023px) {
  .header__logotype {
    width: 95px;
    height: 30px;
    margin-right: 28px;
  }
}
@media (max-width: 767px) {
  .header__logotype {
    display: none;
  }
}
.header__logotype_mobile {
  display: none;
  width: 110px;
  height: 35px;
  position: absolute;
  left: calc((100% - 110px) / 2);
  top: 25px;
  margin-right: 0;
}
@media (max-width: 767px) {
  .header__logotype_mobile {
    display: block;
  }
}
.header__menu {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 152px - 40px);
  height: 82px;
  padding: 26px 0 0 104px;
  background: white;
  position: relative;
  font-size: 16px;
}
@media screen and (max-width: 1200px) {
  .header__menu {
    padding: 26px 0 0 35px;
  }
}
@media (max-width: 1023px) {
  .header__menu {
    padding: 26px 32px;
    height: 128px;
    position: relative;
    z-index: 1;
  }
}
@media (max-width: 767px) {
  .header__menu {
    padding: 0;
    height: auto;
    width: 160px;
  }
}
.header__search {
  text-align: right;
  position: absolute;
  top: 0;
  right: 0;
  left: calc((100% - 1114px) / 2 + (1114px - 260px));
  height: 100%;
  padding: 15px 0;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  width: 260px;
  z-index: 10;
  font-size: 0;
}
@media screen and (max-width: 1200px) {
  .header__search {
    left: auto;
    right: 27px;
  }
}
@media (max-width: 1023px) {
  .header__search {
    left: auto;
    right: 27px;
    width: 20px;
  }
}
@media (max-width: 767px) {
  .header__search {
    width: 100%;
    right: 0;
    padding: 0 30px;
    background: #F0F3F9;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -ms-flex-line-pack: center;
        align-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-item-align: center;
        align-self: center;
  }
}
.header__search_open {
  background: white;
  left: 0;
  width: 100%;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 15px calc((100% - 1114px) / 2);
}
@media screen and (max-width: 1200px) {
  .header__search_open {
    padding: 15px 27px;
  }
}
@media (max-width: 1023px) {
  .header__search_open {
    padding: 15px 20px;
  }
}
@media (max-width: 767px) {
  .header__search_open {
    padding: 15px 30px;
    right: 0;
    left: 0;
  }
}
.header__search_open .header__search-input {
  width: 100%;
  color: #373E4B;
}
@media (max-width: 1023px) {
  .header__search_open .header__search-input {
    display: inline-block;
    vertical-align: middle;
  }
}
@media (max-width: 767px) {
  .header__search_open .header__search-input::-webkit-input-placeholder {
    color: rgba(155, 165, 185, 0.6) !important;
  }
  .header__search_open .header__search-input::-moz-placeholder {
    color: rgba(155, 165, 185, 0.6) !important;
  }
  .header__search_open .header__search-input:-moz-placeholder {
    color: rgba(155, 165, 185, 0.6) !important;
  }
  .header__search_open .header__search-input:-ms-input-placeholder {
    color: rgba(155, 165, 185, 0.6) !important;
  }
}
.header__search_open .header__search-input::-webkit-input-placeholder {
  color: rgba(155, 165, 185, 0.5);
}
.header__search_open .header__search-input::-moz-placeholder {
  color: rgba(155, 165, 185, 0.5);
}
.header__search_open .header__search-input:-moz-placeholder {
  color: rgba(155, 165, 185, 0.5);
}
.header__search_open .header__search-input:-ms-input-placeholder {
  color: rgba(155, 165, 185, 0.5);
}
.header__search_open .header__search-button_open {
  background-image: url("/assets/images/close-blue.svg");
  cursor: pointer;
}
.header__search-input {
  display: inline-block;
  vertical-align: middle;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background: none;
  color: rgba(255, 255, 255, 0.7);
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  width: 224px;
  padding: 0 20px 0 0;
  letter-spacing: 0.03em;
  z-index: 2;
  position: relative;
}
@media (max-width: 1023px) {
  .header__search-input {
    display: none;
  }
}
@media (max-width: 767px) {
  .header__search-input {
    display: block;
    padding: 0;
    color: rgba(155, 165, 185, 0.8);
    width: 100%;
  }
  .header__search-input::-webkit-input-placeholder {
    color: rgba(155, 165, 185, 0.6) !important;
  }
  .header__search-input::-moz-placeholder {
    color: rgba(155, 165, 185, 0.6) !important;
  }
  .header__search-input:-moz-placeholder {
    color: rgba(155, 165, 185, 0.6) !important;
  }
  .header__search-input:-ms-input-placeholder {
    color: rgba(155, 165, 185, 0.6) !important;
  }
}
.header__search-input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.header__search-input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.header__search-input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.header__search-input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.header__search-button {
  background-image: url("/assets/images/search.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  width: 18px;
  height: 18px;
  z-index: 1;
  opacity: 0.5;
}
.header__search-button:hover {
  opacity: 1;
}
@media (max-width: 767px) {
  .header__search-button {
    margin-right: 15px;
    background-image: url("/assets/images/search-grey.svg");
  }
}
.header__search-button_close {
  background-image: url("/assets/images/close-blue.svg");
  cursor: pointer;
  position: absolute;
  top: 17px;
  right: calc((100% - 1114px) / 2);
  z-index: 3;
}
@media screen and (max-width: 1200px) {
  .header__search-button_close {
    right: 27px;
  }
}
.header__search-button_show {
  display: inline-block;
}
.header__search-button_hide {
  display: none;
}
.header__bottom {
  background-color: white;
}
@media (max-width: 767px) {
}
.header__bottom-holder {
  font-size: 0;
}
.header__bottom-holder::after {
  display: none;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 50%;
  background: white;
  z-index: 0;
}
@media (max-width: 1023px) {
  .header__bottom-holder::after {
    display: block;
  }
}
@media (max-width: 767px) {
  .header__bottom-holder::after {
    display: none;
  }
}
@media (max-width: 1023px) {
  .header__bottom-holder {
    padding-right: 0 !important;
    position: relative;
  }
}
@media (max-width: 767px) {
  .header__bottom-holder {
    padding: 32px 30px !important;
  }
}
.header__mobile-bottom {
  background-image: url("/assets/images/pattern.svg");
  background-color: #9BA5B9;
  display: none;
  font-size: 16px;
  padding: 32px 30px 50px 30px;
}
@media (max-width: 767px) {
  .header__mobile-bottom {
    display: block;
  }
}
.header__regions {
  display: inline-block;
  position: relative;
  color: #fff;
  font-size: 14px;
}
.header__regions_toggle {
  width: 100%;
  position: absolute;
  padding: 17px;
  background: rgba(188, 38, 44, 0.73);
  text-align: left;
  z-index: 15;
  list-style: none;
}
.footer {
  background-image: url("/assets/images/pattern.svg");
  padding: 80px 0 40px 0;
  background-color: #383C45;
}
@media (max-width: 767px) {
  .footer {
    padding: 70px 0 30px 0;
  }
}
.footer__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 767px) {
  .footer__top {
    display: block;
  }
}
.footer__logotype {
  width: 152px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("/assets/images/logotype.png");
  margin-right: 42px;
}
@media (max-width: 1023px) {
  .footer__logotype {
    margin-right: 35px;
  }
}
@media (max-width: 767px) {
  .footer__logotype {
    margin-right: 0;
    margin: 0 auto 40px auto;
  }
}
.footer__menu-item {
  line-height: 24px;
  font-size: 16px;
  color: #FFFFFF;
  font-family: 'OpenSans-Regular';
  position: relative;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
@media (max-width: 767px) {
  .footer__menu-item {
    font-size: 14px;
  }
}
.footer__menu-item:hover {
  border-bottom: 1px solid rgba(255, 255, 255, 0);
}
.footer__menu-item-wrap {
  display: block;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .footer__menu-item-wrap {
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
  }
}
.footer__information {
  margin-left: 180px;
}
@media (max-width: 1023px) {
  .footer__information {
    margin-left: 60px;
  }
}
@media (max-width: 767px) {
  .footer__information {
    margin-left: 0;
  }
}
.footer__search {
  position: relative;
  margin-bottom: 55px;
  min-width: 384px;
}
@media (max-width: 1023px) {
  .footer__search {
    min-width: 341px;
  }
}
@media (max-width: 767px) {
  .footer__search {
    margin-bottom: 40px;
    min-width: auto;
  }
}
.footer__search-input {
  font-family: 'OpenSans-Regular';
  line-height: 21px;
  font-size: 14px;
  background: transparent;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding: 11px 11px 11px 0;
  width: 100%;
  color: white;
}
.footer__search-input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.footer__search-input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.footer__search-input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.footer__search-input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
@media (max-width: 767px) {
  .footer__search-input {
    font-size: 12px;
    line-height: 18px;
    padding: 6px 0;
  }
}
.footer__search-button {
  width: 18px;
  height: 100%;
  background-image: url("/assets/images/search-btn.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}
@media (max-width: 767px) {
  .footer__search-button {
    width: 15px;
  }
}
.footer__contacts-row {
  display: inline-block;
  vertical-align: top;
}
@media (max-width: 1023px) {
  .footer__contacts-row {
    display: block;
  }
}
.footer__contacts-item {
  font-family: 'OpenSans-Regular';
  line-height: 24px;
  font-size: 16px;
  color: #FFFFFF;
  display: inline-block;
  position: relative;
  cursor: pointer;
  letter-spacing: 0.05em;
}
@media (max-width: 767px) {
  .footer__contacts-item {
    font-size: 14px;
  }
}
.footer__contacts-item_email {
  display: block;
  margin-bottom: 26px;
}
@media (max-width: 767px) {
  .footer__contacts-item_email {
    margin-bottom: 20px;
  }
}
.footer__contacts-item_phone {
  padding-left: 40px;
  margin-right: 20px;
  margin-bottom: 15px;
}
.footer__contacts-item_phone:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 19px;
  background-image: url("/assets/images/phone-white.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.footer__contacts-item_phone:hover {
  text-decoration: underline;
}
.footer__contacts-item_callback {
  margin-bottom: 15px;
  border-bottom: 1px dashed white;
}
.footer__contacts-item_callback:hover {
  border-bottom: 1px dashed transparent;
}
.footer__bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  position: relative;
  margin-top: 70px;
}
@media (max-width: 767px) {
  .footer__bottom {
    margin-top: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -ms-flex-item-align: center;
        align-self: center;
    -ms-flex-line-pack: start;
        align-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}
.footer__text {
  font-family: 'OpenSans-Regular';
  line-height: 21px;
  font-size: 14px;
  color: #FFFFFF;
  opacity: 0.6;
}
@media (max-width: 767px) {
  .footer__text {
    font-size: 12px;
  }
}
.footer__text_developer {
  position: absolute;
  right: 0;
}
@media (max-width: 767px) {
  .footer__text_developer {
    position: relative;
  }
}
.footer__text_developer .footer__link {
  opacity: 1 !important;
}
@media (max-width: 767px) {
  .footer__text_developer .footer__link {
    margin: 0;
  }
}
.footer__text_p {
  margin-right: 32px;
}
.footer__link {
  font-family: 'OpenSans-Regular';
  line-height: 21px;
  font-size: 14px;
  color: #FFFFFF;
  opacity: 0.6;
  position: relative;
}
@media (max-width: 767px) {
  .footer__link {
    font-size: 12px;
    margin: 15px 0 20px 0;
  }
}
.footer__link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: white;
}
.footer__link:hover::after {
  opacity: 0;
}
.menu {
  font-size: 0;
}
.menu__item {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin-right: 38px;
  font-size: 16px;
}
.menu__item:hover .menu__item-head {
  color: #3B7DFF;
  border-bottom: 1px solid transparent;
}
@media (max-width: 1023px) {
  .menu__item {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 768px) {
  .menu__item:nth-child(4) {
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .menu__item {
    margin-bottom: 20px;
  }
}
.menu__item:last-child {
  margin-right: 0;
}
.menu__item_drop-down {
  margin-right: 58px;
}
.menu__item_drop-down .menu__item-head::after {
  content: '';
  width: 10px;
  height: 7px;
  background-image: url("/assets/images/arrow-down-2.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  top: 8px;
  left: calc(100% + 6px);
}
.menu__item_open .menu__item-head {
  color: #3B7DFF;
}
.menu__item:hover .menu__item-body {
  opacity: 1;
  visibility: visible;
}
.menu__item-head {
  cursor: pointer;
  font-family: 'OpenSans-Semibold';
  color: #315191;
  border-bottom: 1px solid rgba(49, 81, 145, 0.5);
  font-size: 16px;
  line-height: 24px;
  position: relative;
}
.menu__item-body {
  position: absolute;
  top: 100%;
  left: 0;
  padding: 15px 0 0 0;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  visibility: hidden;
  z-index: 10;
}
@media screen and (max-width: 1200px) {
  .menu__item-body {
    right: 0 !important;
    left: auto !important;
  }
}
@media (max-width: 767px) {
  .menu__item-body {
    left: 0 !important;
    width: 270px;
  }
}
.menu__item-body-holder {
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  background: white;
  padding: 30px;
}
.menu__item-elem {
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .menu__item-elem {
    font-size: 14px;
  }
}
.menu__item-elem:last-child {
  margin-bottom: 0;
}
.menu__item-link {
  color: #315191;
  border-bottom: 1px solid #315191;
  line-height: 70%;
  white-space: nowrap;
  font-size: 14px;
}
@media screen and (min-width: 992px) {
  .menu__item-link:hover {
    color: #3B7DFF;
    border-bottom: 1px solid transparent;
  }
}
.item-block {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
  position: relative;
}
.item-block_big .item-block__image {
  height: 300px;
  margin-bottom: 20px;
}
@media (max-width: 1023px) {
  .item-block_big .item-block__image {
    height: 400px;
  }
}
@media (max-width: 767px) {
  .item-block_big .item-block__image {
    height: 157px;
    margin-bottom: 10px;
  }
}
.item-block_big .item-block__date {
  padding: 20px;
}
@media (max-width: 767px) {
  .item-block_big .item-block__date {
    padding: 12px 20px;
  }
}
.item-block_big .item-block__text {
  font-size: 21px;
  line-height: 34px;
  font-family: 'OpenSans-Semibold';
  letter-spacing: 0.03em;
}
@media (max-width: 767px) {
  .item-block_big .item-block__text {
    font-size: 16px;
    line-height: 26px;
  }
}
.item-block__image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 194px;
  border-radius: 3px;
  position: relative;
  margin-bottom: 15px;
  overflow: hidden;
}
@media (max-width: 767px) {
  .item-block__image {
    height: 157px;
    margin-bottom: 10px;
  }
}
.item-block__image-img {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  position: absolute;
  z-index: 0;
}
.item-block__image-play {
  background-image: url("/assets/images/play-2.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
  background-size: 80%;
  width: 50px;
  height: 50px;
  position: absolute;
  left: calc((100% - 50px) / 2);
  top: calc((100% - 50px) / 2);
  z-index: 1;
}
.item-block__date {
  background-image: url("/assets/images/pattern.svg");
  top: 0;
  right: 0;
  bottom: auto;
  left: 0;
  position: absolute;
  opacity: 0.8;
  background-color: #383C45;
  z-index: 1;
  padding: 8px 13px;
  font-size: 14px;
  color: white;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.item-block__date-link {
  display: inline-block;
  vertical-align: middle;
  border-bottom: 1px solid rgba(255, 255, 255, 0.8);
  color: white;
}
.item-block__date-link:hover {
  color: #3B7DFF;
  border-bottom: 1px solid transparent;
}
.item-block__text {
  cursor: pointer;
  display: inline;
  border-bottom: 1px solid #315191;
  line-height: 24px;
  color: #315191;
  font-size: 16px;
}
.item-block__text:hover {
  border-bottom: 1px solid transparent;
  color: #3B7DFF;
}
@media (max-width: 767px) {
  .item-block__text {
    font-size: 14px;
  }
}
.item-block__text span {
  background: #c0d8ff;
}
.video-slider {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  overflow: hidden;
}
@media (max-width: 1023px) {
  .video-slider_main-page .video-slider__list-item {
    width: 137px;
  }
}
@media (max-width: 767px) {
  .video-slider_main-page .video-slider__list-item {
    width: 96px;
  }
}
.video-slider__main {
  margin-bottom: 15px;
  position: relative;
  height: 357px;
  overflow: hidden;
  border-radius: 3px;
}
@media (max-width: 1023px) {
  .video-slider__main {
    height: 400px;
  }
}
@media (max-width: 767px) {
  .video-slider__main {
    margin-bottom: 10px;
    height: 158px;
  }
}
.video-slider__main-wrapper {
  height: 100%;
  white-space: nowrap;
  font-size: 0;
}
.video-slider__main-slide {
  display: inline-block;
  vertical-align: top;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 100%;
}
.video-slider__main-slide iframe {
  display: none;
  width: 100%;
  height: 100%;
}
.video-slider__main-slide_load iframe {
  display: block;
}
.video-slider__main-slide_load .video-slider__main-play {
  display: none;
}
.video-slider__main-play {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.video-slider__main-play::after {
  content: '';
  background-image: url("/assets/images/play.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  display: block;
  width: 70px;
  height: 70px;
  position: absolute;
  top: calc((100% - 70px) / 2);
  left: calc((100% - 70px) / 2);
  background-position: right;
  background-size: 80%;
}
@media (max-width: 767px) {
  .video-slider__main-play::after {
    width: 44px;
    height: 44px;
    top: calc((100% - 44px) / 2);
    left: calc((100% - 44px) / 2);
  }
}
.video-slider__main-play_big::after {
  width: 100px;
  height: 100px;
  top: calc((100% - 100px) / 2);
  left: calc((100% - 100px) / 2);
}
@media (max-width: 767px) {
  .video-slider__main-play_big::after {
    width: 44px;
    height: 44px;
    top: calc((100% - 44px) / 2);
    left: calc((100% - 44px) / 2);
    background-size: 75%;
  }
}
.video-slider__main-play_big::before {
  content: '';
  background-image: url("/assets/images/play-ellipse.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  width: 192px;
  height: 192px;
  position: absolute;
  left: calc((100% - 192px) / 2);
  top: calc((100% - 192px) / 2);
}
@media (max-width: 767px) {
  .video-slider__main-play_big::before {
    width: 84px;
    height: 84px;
    left: calc((100% - 84px) / 2);
    top: calc((100% - 84px) / 2);
  }
}
.video-slider__elem {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  position: absolute;
  border-radius: 3px;
  position: relative;
  z-index: 1;
  display: block;
  margin: auto;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
  width: 100%;
  height: 100%;
}
.video-slider__item {
  display: inline-block;
  vertical-align: top;
}
.video-slider__list-holder {
  white-space: nowrap;
  font-size: 0;
  padding: 0 0 10px 0;
}
.video-slider__list-holder .scrollbar-track-x {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 99px;
  height: 5px;
}
.video-slider__list-holder .scrollbar-track-x .scrollbar-thumb {
  height: 5px;
}
.video-slider__list-item {
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  width: 152px;
  height: 86px;
  position: relative;
  margin: 0 0 9px 0;
}
.video-slider__list-item:last-child {
  margin-right: 0;
}
@media (max-width: 1023px) {
  .video-slider__list-item {
    width: 170px;
  }
}
@media (max-width: 767px) {
  .video-slider__list-item {
    margin: 0 10px 9px 0;
    width: 97px;
    height: 55px;
  }
}
.video-slider__list-item:hover::after {
  opacity: 1;
}
@media (max-width: 767px) {
  .video-slider__list-item:hover::after {
    opacity: 0;
  }
}
.video-slider__list-item_active::after {
  opacity: 1 !important;
}
.video-slider__list-item::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: calc(100% + 7px);
  height: 2px;
  background: #315191;
  border-radius: 99px;
  opacity: 0;
}
.person {
  white-space: normal;
}
.person_contacts .person__image {
  display: inline-block;
  vertical-align: top;
  width: 192px;
  height: 192px;
}
.person_contacts .person__title {
  margin-top: 0;
}
@media (max-width: 767px) {
  .person_contacts .person__title {
    margin-top: 20px;
  }
}
.person__image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 3px;
  position: relative;
  height: 475px;
}
.person__image-img {
  z-index: 1;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.person__content {
  display: inline-block;
  vertical-align: top;
  padding: 0 0 0 30px;
  width: calc(100% - 192px);
}
@media (max-width: 767px) {
  .person__content {
    width: 100%;
    padding: 0;
  }
}
.person__title {
  margin: 10px 0;
  font-size: 20px;
  font-family: 'OpenSans-Semibold';
}
@media (max-width: 767px) {
  .person__title {
    font-size: 18px;
  }
}
.person__text {
  font-size: 16px;
}
@media (max-width: 767px) {
  .person__text {
    font-size: 14px;
  }
}
.person__contacts {
  margin: 10px 0 0 0;
}
.description {
  padding: 80px 0;
}
@media (max-width: 1023px) {
  .description {
    padding: 80px 0;
  }
}
@media (max-width: 767px) {
  .description {
    padding: 50px 0 70px 0;
  }
}
.description__text {
  padding: 0 252px 0 0;
  font-size: 14px;
  line-height: 20px;
}
@media (max-width: 1023px) {
  .description__text {
    padding: 0;
  }
}
.description__text-p {
  margin-bottom: 15px;
}
.description__text-p:last-child {
  margin-bottom: 0;
}
.video-block {
  background-image: url("/assets/images/pattern.svg");
  background-color: #383C45;
  padding: 80px 0;
  position: relative;
}
@media (max-width: 767px) {
  .video-block {
    padding: 50px 0;
  }
}
.video-block__inner {
  position: relative;
  z-index: 1;
}
.video-block__iframe {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 824px;
  height: 465px;
  margin: 0 0 20px 0;
  background: black;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
@media (max-width: 1023px) {
  .video-block__iframe {
    width: 100%;
    max-width: 710px;
    height: 400px;
  }
}
@media (max-width: 767px) {
  .video-block__iframe {
    height: 157px;
    margin: 0 0 10px 0;
  }
}
.video-block__iframe iframe {
  width: 100%;
  height: 100%;
  display: none;
}
.video-block__iframe_load iframe {
  display: block;
}
.video-block__video {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.video-block__play {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  position: absolute;
}
.video-block__play_hide {
  opacity: 0;
  visibility: hidden;
}
.video-block__play::after {
  content: '';
  background-image: url("/assets/images/play.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  display: block;
  position: absolute;
  width: 100px;
  height: 100px;
  top: calc((100% - 100px) / 2);
  left: calc((100% - 100px) / 2);
  background-position: right;
  background-size: 80%;
}
@media (max-width: 767px) {
  .video-block__play::after {
    width: 44px;
    height: 44px;
    top: calc((100% - 44px) / 2);
    left: calc((100% - 44px) / 2);
    background-size: 75%;
  }
}
.video-block__play::before {
  content: '';
  background-image: url("/assets/images/play-ellipse.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  width: 192px;
  height: 192px;
  position: absolute;
  left: calc((100% - 192px) / 2);
  top: calc((100% - 192px) / 2);
}
@media (max-width: 767px) {
  .video-block__play::before {
    width: 84px;
    height: 84px;
    left: calc((100% - 84px) / 2);
    top: calc((100% - 84px) / 2);
  }
}
.video-block__title {
  display: inline;
  font-size: 21px;
  line-height: 34px;
  color: white;
  border-bottom: 1px solid white;
}
@media (max-width: 767px) {
  .video-block__title {
    font-size: 16px;
    line-height: 26px;
  }
}
.video-block__background {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("/assets/images/video-block_bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  margin-left: calc((100% - 1114px) / 2 + 152px + 40px);
  z-index: 0;
}
@media (max-width: 1023px) {
  .video-block__background {
    margin-left: 150px;
  }
}
.modal-window {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-line-pack: center;
      align-content: center;
  -ms-flex-item-align: center;
      align-self: center;
  display: none;
  visibility: hidden;
  opacity: 0;
  overflow-y: auto;
  z-index: 9999;
  padding: 0 0 40px 0;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.modal-window.in {
  opacity: 1;
  visibility: visible;
}
@media (max-width: 767px) {
  .modal-window {
    -webkit-overflow-scrolling: touch;
    padding: 20px !important;
  }
}
.modal-window_min .modal-window__container {
  max-width: 536px;
}
@media (max-width: 1023px) {
  .modal-window_min .modal-window__container {
    max-width: 466px;
  }
}
.modal-window_big .modal-window__container {
  max-width: 920px;
}
@media (max-width: 1023px) {
  .modal-window_big .modal-window__container {
    max-width: 714px;
  }
}
.modal-window_video .modal-window__container {
  width: 1112px;
}
@media screen and (max-width: 1200px) {
  .modal-window_video .modal-window__container {
    width: 778px;
  }
}
@media (max-width: 1023px) {
  .modal-window_video .modal-window__container {
    width: 700px;
  }
}
@media screen and (max-width: 767px) {
  .modal-window_video .modal-window__container {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
  }
}
.modal-window__container {
  -webkit-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  background: white;
  border-radius: 3px;
  position: relative;
  z-index: 1;
  margin: 150px auto auto auto;
}
@media (max-width: 767px) {
  .modal-window__container {
    margin: 0;
  }
}
@media screen and (max-width: 1200px) {
  .modal-window__container_video {
    margin-top: 250px;
  }
}
@media screen and (max-width: 767px) {
  .modal-window__container_video {
    margin-top: 100px;
  }
}
.modal-window__header {
  background-image: url("/assets/images/pattern.svg");
  background-color: #373E4B;
  padding: 40px 54px;
  position: relative;
}
@media (max-width: 1023px) {
  .modal-window__header {
    padding: 60px 20px 40px 20px;
  }
}
@media (max-width: 767px) {
  .modal-window__header {
    padding: 40px 20px 20px 20px;
  }
}
.modal-window__close {
  background-image: url("/assets/images/close-white.svg");
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 1;
}
@media (max-width: 767px) {
  .modal-window__close {
    top: 15px;
    right: 15px;
    width: 15px;
    height: 15px;
  }
}
.modal-window__header-title {
  font-family: 'Oswald-Medium';
  line-height: 47px;
  font-size: 40px;
  color: #FFFFFF;
  letter-spacing: 0.03em;
}
@media (max-width: 767px) {
  .modal-window__header-title {
    font-size: 25px;
    line-height: 30px;
  }
}
.modal-window__header-text {
  font-family: 'OpenSans-Regular';
  line-height: normal;
  font-size: 20px;
  color: #FFFFFF;
  position: absolute;
  top: 80px;
  right: 54px;
  letter-spacing: 0.03em;
}
@media (max-width: 767px) {
  .modal-window__header-text {
    position: static;
    font-size: 18px;
    margin-top: 12px;
  }
}
.modal-window__body {
  padding: 40px 54px;
  font-size: 0;
}
@media (max-width: 1023px) {
  .modal-window__body {
    padding: 40px 32px 60px 32px;
  }
}
@media (max-width: 767px) {
  .modal-window__body {
    padding: 35px 20px 50px 20px;
  }
}
.modal-window__left {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 425px);
  padding: 0 50px 0 0;
}
@media (max-width: 1023px) {
  .modal-window__left {
    width: calc(100% - 310px);
    padding: 0 30px 0 0;
  }
}
@media (max-width: 767px) {
  .modal-window__left {
    width: 100%;
    padding: 0;
    margin: 0 0 20px 0;
  }
}
.modal-window__right {
  display: inline-block;
  vertical-align: top;
  width: 425px;
}
@media (max-width: 1023px) {
  .modal-window__right {
    width: 310px;
  }
}
@media (max-width: 767px) {
  .modal-window__right {
    width: 100%;
  }
}
.modal-window__input {
  font-family: 'OpenSans-Regular';
  line-height: 24px;
  font-size: 16px;
  color: #373E4B;
  border: none;
  border-bottom: 2px solid #373E4B;
  display: block;
  width: 100%;
  padding: 5px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-bottom: 5px;
  margin-top: 15px;
}
.modal-window__input_textarea {
  min-height: 36px;
  font-family: 'OpenSans-Regular';
  overflow: hidden;
  resize: none;
}
.modal-window__input::-webkit-input-placeholder {
  color: #BDBDBD;
}
.modal-window__input::-moz-placeholder {
  color: #BDBDBD;
}
.modal-window__input:-moz-placeholder {
  color: #BDBDBD;
}
.modal-window__input:-ms-input-placeholder {
  color: #BDBDBD;
}
.modal-window__input-message {
  font-family: 'OpenSans-Regular';
  line-height: 21px;
  font-size: 14px;
  color: #373E4B;
}
.modal-window__btn {
  font-family: 'OpenSans-Regular';
  line-height: 24px;
  font-size: 16px;
  color: #FFFFFF;
  padding: 14px 30px;
  margin-top: 40px;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 3px !important;
}
.modal-window__privacy-text {
  font-family: 'OpenSans-Regular';
  line-height: 21px;
  font-size: 14px;
  color: #9BA5B9;
  margin-top: 10px;
}
.modal-window__privacy-link {
  font-family: 'OpenSans-Regular';
  line-height: 21px;
  font-size: 14px;
  color: #315191;
  text-decoration: underline !important;
}
.modal-window__input-file-block {
  padding: 65px 40px;
  border: 2px dashed #9BA5B9;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  cursor: pointer;
}
.modal-window__input-file-btn {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  top: 0;
  left: 0;
  font-size: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
}
.modal-window__input-file-text {
  font-family: 'OpenSans-Regular';
  line-height: 21px;
  font-size: 14px;
  text-align: center;
  color: #666F81;
}
.modal-window__input-file-link {
  font-family: 'OpenSans-Regular';
  line-height: 21px;
  font-size: 14px;
  text-align: center;
  color: #3B7DFF;
  display: inline-block;
}
.modal-window__blackout {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 0;
}
@media (max-width: 767px) {
}
.modal-window__success {
  padding: 0 0 20px 0;
}
.modal-window__success-text {
  font-size: 16px;
  line-height: 24px;
  color: #666F81;
  margin-bottom: 40px;
}
.modal-window__success-button {
  padding: 14px 30px 16px 30px;
  font-size: 16px;
  border-radius: 3px;
}
.modal-window__video {
  width: 1112px;
  height: 625px;
  background: black;
}
@media screen and (max-width: 1200px) {
  .modal-window__video {
    width: 778px;
    height: 437px;
  }
}
@media (max-width: 1023px) {
  .modal-window__video {
    width: 700px;
  }
}
@media screen and (max-width: 767px) {
  .modal-window__video {
    width: 100%;
    height: 180px;
  }
}
.modal-window__video-iframe {
  width: 100%;
  height: 100%;
}
.fade {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.feedback_contacts {
  margin: 40px 0 0 0;
}
.feedback_repair {
  margin: 80px 0 0 0;
}
.feedback_repair .feedback__background {
  background-image: url("/assets/images/bg/bg_feedback-2.jpg");
}
@media (max-width: 1023px) {
  .feedback_repair .feedback__background {
    background-image: url("/assets/images/bg/bg_feedback-2-tablet.jpg");
  }
}
@media (max-width: 767px) {
  .feedback_repair .feedback__background {
    background-image: url("/assets/images/bg/bg_feedback-2-mobile.jpg");
  }
}
.feedback__background {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("/assets/images/bg/bg_feedback.jpg");
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 253px;
  z-index: 0;
}
@media (max-width: 1023px) {
  .feedback__background {
    background-image: url("/assets/images/bg/bg_feedback-table.jpg");
    top: 0;
    right: 0;
    bottom: 0;
    left: 125px;
  }
}
@media (max-width: 767px) {
  .feedback__background {
    top: 0;
    right: 0;
    bottom: 0;
    left: 70px;
  }
}
.feedback__background::after {
  content: '';
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  background: #315191;
  opacity: 0.9;
}
.feedback__body {
  background-image: url("/assets/images/pattern.svg");
  padding: 40px 60px;
  -webkit-box-shadow: 0px 0px 50px rgba(27, 29, 34, 0.5);
          box-shadow: 0px 0px 50px rgba(27, 29, 34, 0.5);
  border-radius: 3px;
  background-color: #383C45;
  position: relative;
  width: calc(100% + 120px);
  left: -60px;
}
@media screen and (max-width: 1280px) {
  .feedback__body {
    left: 0;
    width: 100%;
  }
}
@media (max-width: 1023px) {
  .feedback__body {
    left: 0;
    width: 100%;
    padding: 40px 32px 65px 32px;
  }
}
@media (max-width: 767px) {
  .feedback__body {
    padding: 30px 32px 50px 32px;
  }
}
.feedback__left {
  padding: 0 345px 0 0;
  position: relative;
  z-index: 1;
}
@media (max-width: 1023px) {
  .feedback__left {
    padding: 0;
  }
}
.feedback__right {
  text-align: right;
  position: absolute;
  top: calc((100% - 55px) / 2);
  right: 60px;
  z-index: 1;
}
@media (max-width: 1023px) {
  .feedback__right {
    text-align: left;
    position: relative;
    top: auto;
    right: auto;
    margin: 40px 0 0 0;
  }
}
@media (max-width: 767px) {
  .feedback__right {
    margin: 30px 0 0 0;
  }
}
.feedback__text {
  font-family: 'Oswald-Medium';
  line-height: 47px;
  font-size: 40px;
  color: #FFFFFF;
}
@media (max-width: 767px) {
  .feedback__text {
    font-size: 25px;
    line-height: 30px;
    padding: 0 70px 0 0;
  }
}
.feedback__btn {
  cursor: pointer;
  padding: 15px 30px;
  font-family: 'OpenSans-Regular';
  line-height: 24px;
  font-size: 16px;
  color: #FFFFFF;
  border-radius: 5px;
}
@media (max-width: 1023px) {
  .feedback__btn {
    width: 220px;
  }
}
@media (max-width: 767px) {
  .feedback__btn {
    width: 192px;
    padding: 15px;
  }
}
.pagination {
  text-align: center;
  font-size: 20px;
}
.pagination__content {
  display: inline-block;
  vertical-align: middle;
  padding: 16px 30px;
  border-radius: 3px;
  background: #F0F3F9;
  margin: 0 15px;
  white-space: nowrap;
}
.pagination__el {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  font-size: 16px;
  margin-right: 15px;
}
.pagination__el_active .pagination__link {
  color: #666F81 !important;
  cursor: default;
  border-bottom: 1px solid transparent;
}
.pagination__el:last-child {
  margin-right: 0;
}
.pagination__link {
  font-size: 16px;
  border-bottom: 1px solid #3B7DFF;
  color: #3B7DFF;
}
.pagination__points {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  color: #666F81;
  margin-right: 15px;
}
.pagination__button {
  background-image: url("/assets/images/arrow-right.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  width: 6px;
  height: 10px;
}
.pagination__button_prev {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.breadcrumbs {
  font-size: 0;
}
.breadcrumbs__item {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  font-size: 14px;
  border-bottom: 1px solid #3B7DFF;
  color: #3B7DFF !important;
  margin-right: 25px;
}
.breadcrumbs__item:last-child {
  margin-right: 0;
}
.breadcrumbs__item:hover {
  border-bottom: 1px solid transparent;
}
.breadcrumbs__link {
  color: #3B7DFF !important;
}
.breadcrumbs__arrow {
  display: inline-block;
  vertical-align: middle;
  background-image: url("/assets/images/arrow-right-2.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  width: 8px;
  height: 12px;
  position: absolute;
  top: 5px;
  left: calc(100% + 8px);
}
.breadcrumbs__arrow_down {
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
}
.b-files {
  position: relative;
}
.b-files__btn {
  display: block;
  padding: 65px 40px;
  margin-bottom: 20px;
  border: 2px dashed #9BA5B9;
  color: #3B7DFF;
  text-align: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 14px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.b-files__btn span:not(.b-files__title) {
  border-bottom: none;
  color: #666F81;
}
.b-files__btn.hover,
.b-files__btn:hover {
  border-color: #3B7DFF;
  text-decoration: none;
  color: #3B7DFF;
}
.b-files__btn.hover span,
.b-files__btn:hover span {
  border-bottom-color: transparent;
}
.b-files__inp {
  display: none;
}
.b-files__list {
  display: block;
  list-style-type: none;
  margin: 20px 0 0;
  font-size: 14px;
}
.b-files__item {
  display: block;
  vertical-align: top;
  position: relative;
  margin-right: 20px;
  margin-bottom: 5px;
}
.b-files__item-wrap {
  position: relative;
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
  padding-right: 30px;
}
.b-files__item-name {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  vertical-align: top;
  color: #3B7DFF;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding-bottom: 1px;
}
.b-files__item-name span {
  border-bottom: 1px solid rgba(59, 125, 255, 0.3);
  -webkit-transition: border-bottom 0.3s ease-in-out;
  -o-transition: border-bottom 0.3s ease-in-out;
  transition: border-bottom 0.3s ease-in-out;
}
.b-files__item-name:hover {
  color: #315191;
}
.b-files__item-name:hover span {
  border-bottom-color: transparent;
}
.b-files__item-del {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 24px;
  line-height: 1;
  width: 24px;
  height: 24px;
  text-align: center;
  color: #ff4330 !important;
}
.b-files__item-del:hover {
  color: #ff6655 !important;
}
.main-promo {
  background-image: url("/assets/images/pattern.svg");
  padding: 82px 0 0 0;
  background-color: #383C45;
  overflow: hidden;
  position: relative;
  min-height: 406px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
      align-content: center;
  -ms-flex-item-align: center;
      align-self: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
@media (max-width: 1023px) {
  .main-promo {
    min-height: 303px;
    padding: 188px 0 60px 0;
    display: block;
  }
}
@media (max-width: 767px) {
  .main-promo {
    padding: 50px 0 !important;
    min-height: auto;
  }
}
.main-promo_about-production {
  padding: 162px 0 80px 0;
}
@media (max-width: 1023px) {
  .main-promo_about-production {
    padding: 186px 0 60px 0;
  }
}
.main-promo_big {
  min-height: 782px;
  padding: 50px 0 100px 0 !important;
}
@media (max-width: 1023px) {
  .main-promo_big {
    min-height: 630px;
  }
}
@media (max-width: 767px) {
  .main-promo_big {
    min-height: 460px;
  }
}
.main-promo_big .main-promo__image::after {
  display: none;
}
.main-promo_big .main-promo__slider-slide::after {
  opacity: 0;
}
.main-promo__holder {
  position: relative;
  z-index: 1;
}
.main-promo__title {
  max-width: 920px;
  color: white;
  letter-spacing: 0.03em;
  font-weight: 300;
}
.main-promo__text {
  max-width: 567px;
  color: white;
  margin-top: 25px;
  line-height: 24px;
  letter-spacing: 0.05em;
}
@media (max-width: 767px) {
  .main-promo__text {
    font-size: 14px;
    line-height: 20px;
    margin-top: 15px;
  }
}
.main-promo__text_big {
  max-width: 770px;
}
.main-promo__image {
  top: 82px;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-position: top;
  position: absolute;
}
@media screen and (max-width: 1200px) {
  .main-promo__image {
    margin-left: 0;
  }
}
@media (max-width: 1023px) {
  .main-promo__image {
    top: 128px;
    left: 0;
  }
}
@media (max-width: 767px) {
  .main-promo__image {
    margin-left: 0;
    top: 0;
  }
}
.main-promo__image::after {
  content: '';
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  background: #315191;
  opacity: 0.4;
}
.main-promo__image_vacancy::after {
  opacity: 0.3;
}
.main-promo__image_catalog {
  background-position: bottom;
}
.main-promo__image_w100 {
  margin-left: 0;
}
@media screen and (max-width: 1200px) {
  .main-promo__image_w100 {
    margin-left: 0;
    width: 100%;
  }
}
.main-promo__image_w100 .main-promo__slider-slide-content {
  left: 0;
  width: 1114px;
  margin: auto;
}
.main-promo__image_w100 .main-promo__navigation {
  right: auto;
  left: calc((100% - 1114px) / 2);
  z-index: 10;
}
@media screen and (max-width: 1200px) {
  .main-promo__image_w100 .main-promo__navigation {
    left: 27px;
  }
}
.main-promo__image_w100 .main-promo__pagination {
  left: calc((100% - 1114px) / 2 + 155px);
}
.main-promo__image-img {
  max-height: 100%;
  max-width: 100%;
  height: 100%;
  margin: auto;
  display: block;
}
.main-promo__slider {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.main-promo__slider-holder {
  height: 100%;
}
.main-promo__slider-slide {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100%;
  padding: 137px 0;
  opacity: 0 !important;
  visibility: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
      align-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-item-align: center;
      align-self: center;
}
@media screen and (max-width: 1200px) {
  .main-promo__slider-slide {
    padding: 137px 27px;
  }
}
@media (max-width: 1023px) {
  .main-promo__slider-slide {
    padding: 60px 27px;
  }
}
@media (max-width: 767px) {
  .main-promo__slider-slide {
    padding: 50px 20px;
  }
}
.main-promo__slider-slide_active {
  opacity: 1 !important;
  visibility: visible;
}
.main-promo__slider-slide::after {
  content: '';
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  background: #315191;
  opacity: 0.3;
  z-index: 0;
}
.main-promo__slider-slide-content {
  position: relative;
  z-index: 1;
  left: 0;
  width: 1114px;
  margin: auto;
}
@media screen and (max-width: 1200px) {
  .main-promo__slider-slide-content {
    left: 0;
    width: 100%;
  }
}
@media (max-width: 1023px) {
}
@media (max-width: 767px) {
  .main-promo__slider-slide-content {
    left: 0;
  }
}
.main-promo__slider-slide-title {
  max-width: 920px;
  color: white;
  font-weight: 300;
  font-size: 45px;
  line-height: 3.3125rem;
}
@media (max-width: 767px) {
  .main-promo__slider-slide-title {
    font-size: 30px;
    line-height: 36px;
  }
}
.main-promo__slider-slide-text {
  max-width: 567px;
  color: white;
  margin-top: 25px;
  line-height: 24px;
  letter-spacing: 0.05em;
}
@media (max-width: 767px) {
  .main-promo__slider-slide-text {
    font-size: 14px;
    line-height: 20px;
    margin-top: 15px;
  }
}
.main-promo__slider-slide-text_big {
  max-width: 770px;
}
.main-promo__link-youtube {
  margin-top: 30px;
}
.main-promo__navigation {
  position: absolute;
  bottom: 25px;
  white-space: nowrap;
  right: auto;
  left: calc((100% - 1114px) / 2);
  z-index: 10;
}
@media screen and (max-width: 1200px) {
  .main-promo__navigation {
    left: 27px;
  }
}
@media (max-width: 767px) {
  .main-promo__navigation {
    display: none !important;
  }
}
.main-promo__navigation-button {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  width: 40px;
  height: 40px;
}
.main-promo__navigation-button_prev {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.main-promo__navigation-button_next {
  margin-left: 15px;
}
@media screen and (min-width: 992px) {
  .main-promo__navigation-button:hover path {
    opacity: 1;
  }
}
.main-promo__pagination {
  position: absolute;
  bottom: 37px;
  z-index: 5;
  left: calc((100% - 1114px) / 2 + 155px);
}
@media screen and (max-width: 1200px) {
  .main-promo__pagination {
    left: calc(155px + 27px);
  }
}
@media (max-width: 767px) {
  .main-promo__pagination {
    text-align: center;
    bottom: 50px;
    left: 20px;
    right: 20px;
  }
}
.main-promo__pagination-bullet {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  width: 17px;
  height: 17px;
  background: rgba(255, 255, 255, 0.3);
  margin-right: 25px;
  border-radius: 3px;
  position: relative;
}
.main-promo__pagination-bullet:hover::before {
  opacity: 1;
  visibility: visible;
}
.main-promo__pagination-bullet::before {
  content: '';
  position: absolute;
  left: -7px;
  top: -7px;
  right: -7px;
  bottom: -7px;
  border-radius: 3px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.main-promo__pagination-bullet:last-child {
  margin-right: 0;
}
.main-promo__pagination-bullet_active {
  background: rgba(255, 255, 255, 0.6);
}
.main-promo__pagination-bullet_active::before {
  opacity: 0 !important;
}
.main-equipment {
  padding: 60px 0 75px 0;
}
@media (max-width: 767px) {
  .main-equipment {
    padding: 50px 0;
  }
}
.main-equipment__title {
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .main-equipment__title {
    margin-bottom: 20px;
  }
}
.main-equipment__link {
  color: #315191;
  border-bottom: 1px solid #315191;
}
.main-equipment__link:hover {
  border-bottom: 1px solid transparent;
  color: #3B7DFF;
}
.main-equipment__content {
  font-size: 0;
}
.main-equipment__item {
  width: calc((100% - 40px) / 2);
  font-size: 16px;
  margin-top: 40px;
}
@media (max-width: 1023px) {
  .main-equipment__item {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .main-equipment__item {
    margin-top: 30px;
  }
}
.main-equipment__item:nth-child(odd) {
  margin-right: 40px;
}
.main-about {
  background-image: url("/assets/images/pattern.svg");
  padding: 60px 0 80px 0;
  background-color: #383C45;
  overflow: hidden;
  position: relative;
}
@media (max-width: 767px) {
  .main-about {
    padding: 50px 0 70px 0;
  }
}
.main-about__background {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("/assets/images/bg/bg_main-about.jpg");
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin-left: calc((100% - 1114px) / 2 + 152px + 40px);
  z-index: 0;
}
@media (max-width: 1023px) {
  .main-about__background {
    top: 0;
    right: 0;
    bottom: 0;
    left: 125px;
    background-position: 175px center;
  }
}
@media (max-width: 767px) {
  .main-about__background {
    top: 0;
    right: 0;
    bottom: 0;
    left: 70px;
  }
}
.main-about__background::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #315191;
  opacity: 0.9;
}
@media (max-width: 767px) {
  .main-about__background::after {
    left: 175px;
  }
}
.main-about__inner {
  position: relative;
  z-index: 1;
}
.main-about__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
@media (max-width: 1023px) {
  .main-about__row {
    display: block;
  }
}
.main-about__left {
  display: inline-block;
  vertical-align: top;
  width: 632px;
  padding-right: 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
@media (max-width: 1023px) {
  .main-about__left {
    max-width: 632px;
    padding: 0;
  }
}
@media (max-width: 767px) {
  .main-about__left {
    width: 100%;
  }
}
.main-about__right {
  display: inline-block;
  vertical-align: top;
  padding-left: 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: calc(100% - 632px);
}
@media (max-width: 1023px) {
  .main-about__right {
    min-width: 440px;
    padding: 0;
    margin: 2.5rem 0 0 0;
  }
}
@media (max-width: 767px) {
  .main-about__right {
    min-width: auto;
    width: 100%;
    margin: 1.875rem 0 0 0;
  }
}
.main-about__right h3 {
  color: white !important;
}
.main-about__right p {
  opacity: 0.8;
  color: white;
}
.main-about__title {
  line-height: 47px;
  margin-bottom: 30px;
  color: white;
}
@media (max-width: 1023px) {
  .main-about__title {
    padding: 0 1.25rem 0 0;
  }
}
@media (max-width: 767px) {
  .main-about__title {
    line-height: 30px;
    margin-bottom: 30px;
  }
}
.main-about__title_small {
  line-height: normal;
  font-size: 20px;
}
.main-about__video {
  overflow: hidden;
}
.main-about__video-main {
  margin-bottom: 15px;
}
.main-about__video-elem {
  width: 100%;
}
.main-about__video-list {
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  overflow: scroll;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.main-about__video-list .main-about__video-list-item {
  margin-right: 15px;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 20%;
          flex: 0 0 20%;
  max-width: 20%;
}
.main-about__text-p {
  font-family: 'OpenSans-Regular';
  line-height: 24px;
  font-size: 16px;
  color: #FFFFFF;
  opacity: 0.8;
}
.main-about__button {
  line-height: 24px;
  font-size: 16px;
  color: #315191;
  padding: 15px 30px;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.35);
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.35);
  border-radius: 5px;
  display: inline-block;
  margin-top: 25px;
  cursor: pointer;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}
.main-about__button:hover {
  background: #3B7DFF;
  color: white;
}
@media (max-width: 767px) {
  .main-about__button {
    margin-top: 20px;
  }
}
.main-statistics {
  background: #F0F3F9;
  padding: 60px 0 80px 0;
  font-size: 0;
}
@media (max-width: 767px) {
  .main-statistics {
    padding: 50px 0 70px 0;
  }
}
.main-statistics__item {
  display: inline-block;
  vertical-align: top;
  width: calc((100% - 95px * 2 - 1px) / 3);
  position: relative;
}
@media (max-width: 1023px) {
  .main-statistics__item {
    width: calc((100% - 30px - 1px) / 2);
  }
  .main-statistics__item:first-child {
    margin: 0 1.875rem 2.5rem 0;
  }
}
@media (max-width: 767px) {
  .main-statistics__item {
    width: 100%;
  }
  .main-statistics__item:first-child {
    margin: 0;
  }
}
.main-statistics__item:nth-child(3n+2) {
  margin: 0 95px;
}
@media (max-width: 1023px) {
  .main-statistics__item:nth-child(3n+2) {
    margin: 0;
  }
}
@media (max-width: 767px) {
  .main-statistics__item:nth-child(3n+2) {
    margin: 1.875rem 0;
  }
}
.main-statistics__item-icon {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  width: 152px;
  height: 152px;
  position: absolute;
  top: 5px;
  left: 0;
  z-index: 0;
}
.main-statistics__item-icon_production {
  background-image: url("/assets/images/statistics-1.svg");
}
.main-statistics__item-icon_control {
  background-image: url("/assets/images/statistics-2.svg");
}
.main-statistics__item-icon_training {
  background-image: url("/assets/images/statistics-3.svg");
}
.main-statistics__item-content {
  position: relative;
  z-index: 1;
}
.main-statistics__item-title {
  color: #373E4B;
  margin-bottom: 5px;
}
@media (max-width: 767px) {
  .main-statistics__item-title {
    font-size: 18px;
  }
}
.main-statistics__item-value {
  color: #373E4B;
  line-height: 95px;
  font-size: 80px;
  font-family: 'Oswald-Medium';
}
.main-statistics__item-text {
  font-size: 16px;
  line-height: 24px;
  margin-top: 5px;
}
@media (max-width: 767px) {
  .main-statistics__item-text {
    font-size: 14px;
    line-height: 20px;
  }
}
.main-clients {
  padding: 80px 0 0 0;
}
@media (max-width: 767px) {
  .main-clients {
    padding: 50px 0 0 0;
  }
}
.main-clients__title {
  display: inline-block;
  vertical-align: middle;
  margin-right: 30px;
}
@media (max-width: 1023px) {
  .main-clients__title {
    width: 100%;
    margin-bottom: 10px;
  }
}
.main-clients__content {
  margin-top: 30px;
  font-size: 0;
}
.main-clients__link {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}
@media (max-width: 767px) {
  .main-clients__link {
    font-size: 14px;
  }
}
.main-clients__item {
  display: inline-block;
  vertical-align: middle;
  margin: 0 88px 40px 0;
  width: calc((100% - 88px * 3 - 1px) / 4);
  height: 140px;
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-line-pack: center;
      align-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-item-align: center;
      align-self: center;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.main-clients__item:nth-child(4n) {
  margin-right: 0;
}
@media (max-width: 1023px) {
  .main-clients__item {
    width: calc((100% - 30px * 2 - 1px) / 3);
    margin: 0 0 40px 0;
  }
  .main-clients__item:nth-child(3n+2) {
    margin: 0 30px 40px 30px;
  }
}
@media (max-width: 767px) {
  .main-clients__item {
    height: 180px;
    width: 100%;
    margin: 0 0 30px 0;
  }
  .main-clients__item:nth-child(4n) {
    margin-right: 30px;
  }
  .main-clients__item:nth-child(3n+2) {
    margin: 0 0 30px 0 !important;
  }
  .main-clients__item:last-child {
    margin: 0;
  }
}
.main-clients__item_no-flex {
  display: inline-block;
}
.main-clients__item:hover {
  opacity: 0.7;
}
.main-clients__item-image {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}
.main-news {
  padding: 40px 0 80px 0;
}
@media (max-width: 1023px) {
  .main-news {
    padding: 40px 0;
  }
}
.main-news__title {
  display: inline-block;
  vertical-align: middle;
  margin-right: 2rem;
}
@media (max-width: 767px) {
  .main-news__title {
    margin-bottom: 10px;
  }
}
.main-news__link {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: 2px;
}
@media (max-width: 767px) {
  .main-news__link {
    font-size: 14px;
  }
}
.main-news__content {
  font-size: 0;
  white-space: nowrap;
  margin-top: 30px;
}
@media (max-width: 1023px) {
  .main-news__content {
    white-space: normal;
  }
}
.main-news__item {
  width: calc((100% - 40px * 2) / 3);
  margin-right: 40px;
}
@media (max-width: 1023px) {
  .main-news__item {
    width: calc((100% - 30px) / 2);
    margin: 0 0 40px 0;
  }
  .main-news__item:nth-child(odd) {
    margin: 0 30px 40px 0;
  }
  .main-news__item:nth-child(3n) {
    margin-right: 30px !important;
  }
}
@media (max-width: 767px) {
  .main-news__item {
    width: 100%;
    margin: 0 0 30px 0;
  }
  .main-news__item:nth-child(odd) {
    margin: 0 0 30px 0;
  }
}
.main-news__item:nth-child(3n) {
  margin-right: 0;
}
.about {
  padding: 20px 0 80px 0;
}
@media (max-width: 767px) {
  .about {
    padding: 30px 0 50px 0 !important;
  }
}
.about__tabs {
  margin: 0 0 60px 0;
}
@media (max-width: 767px) {
  .about__tabs {
    margin: 0 0 30px 0;
  }
}
.about__left {
  display: inline-block;
  vertical-align: top;
  width: 632px;
  margin: 0 0 60px 0;
}
@media (max-width: 1023px) {
  .about__left {
    width: 100%;
    margin: 0;
  }
}
.about__slider {
  margin: 0 0 60px 0;
}
@media (max-width: 1023px) {
  .about__slider {
    margin: 0 0 50px 0;
  }
}
@media (max-width: 767px) {
  .about__slider {
    margin: 0 0 25px 0;
  }
}
.about__iso {
  background-image: url("/assets/images/pattern.svg");
  background-color: #383C45;
  padding: 20px 30px;
  border-radius: 3px;
  font-size: 0;
}
@media (max-width: 1023px) {
  .about__iso {
    display: none;
  }
}
.about__iso_tablet {
  display: none;
  margin: 60px 0 0 0;
}
@media (max-width: 1023px) {
  .about__iso_tablet {
    display: block;
  }
}
@media (max-width: 767px) {
  .about__iso_tablet {
    margin: 50px 0;
    padding: 25px 20px 50px 20px;
  }
}
.about__iso-icon {
  background-image: url("/assets/images/iso.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  vertical-align: middle;
  width: 59px;
  height: 52px;
}
@media (max-width: 767px) {
  .about__iso-icon {
    width: 100%;
    margin: 0 0 20px 0;
  }
}
.about__iso-text {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  line-height: 21px;
  padding-left: 30px;
  width: calc(100% - 59px);
  color: white;
  letter-spacing: 0.02rem;
}
@media (max-width: 767px) {
  .about__iso-text {
    font-size: 12px;
    line-height: 18px;
    width: 100%;
    padding: 0;
  }
}
.about__right {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 632px);
  padding: 0 0 0 49px;
  margin: 0 0 60px 0;
}
@media (max-width: 1023px) {
  .about__right {
    padding: 0;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .about__right {
    margin: 0 0 0 0;
  }
}
.about__title {
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .about__title {
    margin-bottom: 10px;
  }
}
.about__text-p {
  margin-bottom: 10px;
  line-height: 24px;
}
@media (max-width: 767px) {
  .about__text-p {
    font-size: 14px;
    line-height: 20px;
  }
}
.about__text-p:last-child {
  margin-bottom: 0;
}
.about__information {
  background: #F0F3F9;
  padding: 60px;
  border-radius: 3px;
  font-size: 0;
  margin: 0 0 60px 0;
  position: relative;
  left: -60px;
  width: calc(100% + 60px + 60px);
}
@media (max-width: 1023px) {
  .about__information {
    width: 100%;
    padding: 60px 32px;
    left: 0;
  }
}
@media (max-width: 767px) {
  .about__information {
    padding: 52px 20px 72px 20px;
    margin: 0 0 50px 0;
  }
}
.about__information-item {
  display: inline-block;
  vertical-align: middle;
  width: calc((100% - 70px * 2) / 3);
  margin-right: 70px;
}
@media (max-width: 1023px) {
  .about__information-item {
    width: calc((100% - 30px) / 2);
    margin-right: 30px;
  }
  .about__information-item:nth-child(3n+2) {
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .about__information-item {
    width: 100%;
    margin-right: 0;
  }
  .about__information-item:nth-child(3n+2) {
    margin: 45px 0;
  }
}
.about__information-item:last-child {
  margin-right: 0;
}
@media (max-width: 1023px) {
  .about__information-item:last-child {
    margin: 40px 0 0 0;
  }
}
@media (max-width: 767px) {
  .about__information-item:last-child {
    margin: 0;
  }
}
.about__information-item-icon {
  display: inline-block;
  vertical-align: middle;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  width: 97px;
  height: 97px;
}
@media (max-width: 767px) {
  .about__information-item-icon {
    width: 95px;
    height: 95px;
  }
}
.about__information-item-text {
  display: inline-block;
  vertical-align: middle;
  padding: 0 0 0 20px;
  width: calc(100% - 97px);
  color: #373E4B;
  font-size: 16px;
  line-height: 24px;
}
@media (max-width: 767px) {
  .about__information-item-text {
    width: calc(100% - 95px);
    font-size: 14px;
    line-height: 20px;
  }
}
.about__team {
  margin: 0 0 60px 0;
}
@media (max-width: 1023px) {
  .about__team {
    width: calc(100% - 62px);
  }
}
@media (max-width: 767px) {
  .about__team {
    width: 100%;
    margin: 0;
  }
}
.about__team + .about__awards {
  margin-top: 40px;
}
.about__team-title {
  margin: 0 0 30px 0;
}
@media (max-width: 767px) {
  .about__team-title {
    margin: 0 0 10px 0;
  }
}
.about__team-text {
  margin: 0 0 30px 0;
  max-width: 864px;
}
@media (max-width: 767px) {
  .about__team-text {
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }
}
.about__team-content {
  position: relative;
  max-width: 700px;
  width: 100%;
}
@media (max-width: 767px) {
  .about__team-content {
    margin-top: 20px;
  }
}
.about__team-holder {
  overflow: hidden;
}
.about__team-wrapper {
  font-size: 0;
  white-space: nowrap;
}
.about__team-item {
  display: inline-block;
  vertical-align: top;
  margin: 0 40px 0 0;
  width: calc((100% - 40px * 3) / 4);
}
@media (max-width: 1023px) {
  .about__team-item {
    width: 248px;
  }
}
@media (max-width: 767px) {
  .about__team-item {
    width: 100%;
    margin: 0 0 30px 0;
  }
  .about__team-item:last-child {
    margin: 0;
  }
}
.about__team-item:nth-child(4n) {
  margin-right: 0;
}
@media (max-width: 767px) {
  .about__team-item-image {
    width: 100%;
    height: 100%;
  }
}
.about__team-navigation {
  position: absolute;
  top: 40%;
}
.about__team-navigation_prev {
  right: calc(100% + 30px);
}
.about__team-navigation_next {
  left: calc(100% + 30px);
}
@media (max-width: 767px) {
  .about__team-navigation {
    top: 26%;
  }
  .about__team-navigation_prev {
    right: auto;
    left: 5px;
  }
  .about__team-navigation_next {
    left: auto;
    right: 5px;
  }
}
.about__history {
  margin: 0 0 80px 0;
}
.about__history-title {
  margin: 0 0 30px 0;
}
@media (max-width: 767px) {
  .about__history-title {
    display: none;
  }
}
.about__history-content {
  font-size: 0;
}
.about__history-text {
  line-height: 24px;
}
@media (max-width: 767px) {
  .about__history-text {
    display: none;
  }
}
.about__history-item {
  display: inline-block;
  vertical-align: top;
  margin: 40px 40px 0 0;
  width: calc((100% - 40px * 3) / 4);
}
@media (max-width: 1023px) {
  .about__history-item {
    width: calc((100% - 30px * 2) / 3);
    margin-right: 0;
  }
  .about__history-item:nth-child(3n+2) {
    margin: 40px 30px 0 30px;
  }
  .about__history-item:nth-child(3n) {
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .about__history-item {
    margin: 30px 0 0 0;
    width: calc((100% - 20px) / 2);
  }
  .about__history-item:nth-child(3n+2) {
    margin: 30px 0 0 0;
  }
  .about__history-item:nth-child(odd) {
    margin: 30px 20px 0 0;
  }
}
.about__history-item:nth-child(4n) {
  margin-right: 0;
}
.about__history-item-title {
  font-family: 'Oswald-Medium';
  font-size: 40px;
  color: #315191;
  line-height: 100%;
}
@media (max-width: 767px) {
  .about__history-item-title {
    font-size: 25px;
  }
}
.about__history-item-text {
  font-size: 16px;
}
@media (max-width: 767px) {
  .about__history-item-text {
    font-size: 12px;
    line-height: 18px;
  }
}
.about__history-item-arrow {
  background-image: url("/assets/images/arrow.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-position: right;
  background-size: 100% 100%;
  height: 8px;
  margin: 20px 0;
}
@media (max-width: 767px) {
  .about__history-item-arrow {
    margin: 10px 0;
  }
}
.about__awards {
  margin-bottom: 60px;
}
@media (max-width: 1023px) {
  .about__awards {
    width: calc(100% - 62px);
  }
}
@media (max-width: 767px) {
  .about__awards {
    width: 100%;
    margin-bottom: 20px;
  }
}
.about__awards-title {
  margin: 0 0 30px 0;
}
@media (max-width: 767px) {
  .about__awards-title {
    margin: 0 0 10px 0;
  }
}
.about__awards-text {
  margin: 0 0 30px 0;
}
@media (max-width: 767px) {
  .about__awards-text {
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 20px 0;
  }
}
.about__awards-content {
  position: relative;
  width: calc(100% - 14px);
}
.about__awards-holder {
  overflow: hidden;
}
.about__awards-wrapper {
  white-space: nowrap;
  font-size: 0;
}
.about__awards-item {
  width: calc((100% - 40px * 2) / 3);
  margin: 0 40px 0 0;
}
@media (max-width: 1023px) {
  .about__awards-item {
    width: 342px;
  }
}
@media (max-width: 767px) {
  .about__awards-item {
    width: 100%;
    margin: 0;
  }
}
.about__awards-item:nth-child(3n) {
  margin-right: 0;
}
.about__awards-navigation {
  position: absolute;
  top: 85px;
}
@media (max-width: 767px) {
  .about__awards-navigation {
    top: 66px;
  }
}
.about__awards-navigation_prev {
  right: calc(100% + 30px);
}
.about__awards-navigation_next {
  left: calc(100% + 30px);
}
@media (max-width: 767px) {
  .about__awards-navigation_next {
    left: calc(100% + 12px);
  }
}
.about-production {
  padding: 20px 0 40px 0;
}
@media (max-width: 767px) {
  .about-production {
    padding: 20px 0 30px 0;
  }
}
.about-production__tabs {
  margin: 0 0 60px 0;
}
@media (max-width: 767px) {
  .about-production__tabs {
    margin: 0 0 30px 0;
  }
}
.about-production__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 40px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 1023px) {
  .about-production__item {
    display: block;
  }
}
@media (max-width: 767px) {
  .about-production__item {
    margin-bottom: 30px;
  }
}
.about-production__item:nth-child(even) .about-production__item-text {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
  padding: 0 40px 0 0;
}
.about-production__item:nth-child(even) .about-production__item-image {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
}
.about-production__item-text {
  padding: 0 0 0 40px;
  width: calc(100% - 546px);
}
@media (max-width: 1023px) {
  .about-production__item-text {
    width: 100%;
    padding: 0;
  }
}
.about-production__item-title {
  font-family: 'OpenSans-Semibold';
  line-height: normal;
  font-size: 20px;
  color: #373E4B;
  margin-bottom: 15px;
  letter-spacing: 0;
}
@media (max-width: 767px) {
  .about-production__item-title {
    font-size: 18px;
  }
}
.about-production__item-text-p {
  font-family: 'OpenSans-Regular';
  line-height: 24px;
  font-size: 16px;
  color: #666F81;
}
@media (max-width: 767px) {
  .about-production__item-text-p {
    font-size: 14px;
    line-height: 20px;
  }
}
.about-production__item-image {
  width: 536px;
  height: 296px;
  border-radius: 3px;
  overflow: hidden;
}
@media (max-width: 1023px) {
  .about-production__item-image {
    width: 100%;
    height: 395px;
    margin: 0 0 20px 0;
  }
}
@media (max-width: 767px) {
  .about-production__item-image {
    height: 155px;
  }
}
.about-production__item-image-src {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.about-trust-us {
  padding: 20px 0 40px 0;
}
@media (max-width: 767px) {
  .about-trust-us {
    padding: 20px 0 40px 0;
  }
}
.about-trust-us__tabs {
  margin: 0 0 60px 0;
}
@media (max-width: 767px) {
  .about-trust-us__tabs {
    margin: 0 0 30px 0;
  }
}
.about-trust-us__container {
  padding: 0 0 40px 0;
  font-size: 0;
}
@media (max-width: 767px) {
  .about-trust-us__container {
    padding: 40px 0 50px 0;
  }
}
.about-trust-us__item {
  display: inline-block;
  vertical-align: top;
  width: calc((100% - 40px * 2) / 3);
  margin-right: 40px;
  margin-bottom: 40px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media (max-width: 1023px) {
  .about-trust-us__item {
    width: calc((100% - 30px) / 2);
    margin-right: 0;
  }
  .about-trust-us__item:nth-child(odd) {
    margin-right: 30px;
  }
}
@media (max-width: 767px) {
  .about-trust-us__item {
    width: 100%;
    margin-bottom: 30px;
  }
  .about-trust-us__item:nth-child(odd) {
    margin-right: 0;
  }
  .about-trust-us__item:last-child {
    margin-bottom: 0;
  }
}
.about-trust-us__item:nth-child(3n) {
  margin-right: 0;
}
.about-trust-us__item:hover {
  opacity: 0.7;
}
.about-trust-us__item-logo {
  height: 194px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -ms-flex-line-pack: center;
      align-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-item-align: center;
      align-self: center;
}
@media (max-width: 767px) {
  .about-trust-us__item-logo {
    height: 157px;
  }
}
.about-trust-us__item-logo-src {
  max-width: 200px;
  max-height: 140px;
  height: auto;
  width: auto;
  margin: auto 0;
}
@media (max-width: 767px) {
  .about-trust-us__item-logo-src {
    max-height: 114px;
    max-width: 163px;
  }
}
.about-trust-us__item-title {
  line-height: normal;
  font-size: 20px;
  color: #315191;
  margin-top: 15px;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .about-trust-us__item-title {
    font-size: 18px;
  }
}
.about-trust-us__item-text {
  line-height: 24px;
  font-size: 16px;
  color: #666F81;
}
@media (max-width: 767px) {
  .about-trust-us__item-text {
    font-size: 14px;
    line-height: 20px;
  }
}
.about-documents {
  padding: 20px 0 40px;
}
.about-documents__tabs {
  margin: 0 0 60px 0;
}
.about-documents__container {
  font-size: 0 !important;
}
.about-documents__item {
  display: inline-block;
  vertical-align: top;
  margin-bottom: 40px;
  width: calc((100% - 40px * 3) / 4);
  margin-right: 40px;
}
@media (max-width: 1023px) {
  .about-documents__item {
    width: calc((100% - 30px * 2) / 3);
    margin-right: 30px;
  }
  .about-documents__item:nth-child(3n) {
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .about-documents__item {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }
}
.about-documents__item:nth-child(4n) {
  margin-right: 0;
}
@media (max-width: 1023px) {
  .about-documents__item:nth-child(4n) {
    margin-right: 30px;
  }
}
@media (max-width: 767px) {
  .about-documents__item:nth-child(4n) {
    margin-right: 0;
  }
}
.about-documents__item:hover .about-documents__item-link {
  color: #3B7DFF;
  text-decoration: none !important;
}
.about-documents__item-img {
  margin-bottom: 15px;
}
.about-documents__item-img-src {
  width: 100%;
}
.about-documents__item-link {
  font-family: 'OpenSans-Regular';
  line-height: 24px;
  font-size: 16px;
  color: #315191;
  text-decoration: underline !important;
}
.about-vacancy {
  padding: 20px 0 20px 0;
}
@media (max-width: 767px) {
  .about-vacancy {
    padding: 20px 0 40px 0 !important;
  }
}
.about-vacancy__tabs {
  margin: 0 0 60px 0;
}
@media (max-width: 767px) {
  .about-vacancy__tabs {
    margin: 0 0 30px 0;
  }
}
.about-vacancy__item {
  background: #F0F3F9;
  border-radius: 3px;
  padding: 60px;
  margin: 0 0 60px 0;
  position: relative;
  left: -60px;
  width: calc(100% + 120px);
}
@media screen and (max-width: 1280px) {
  .about-vacancy__item {
    left: 0;
    width: 100%;
  }
}
@media (max-width: 1023px) {
  .about-vacancy__item {
    padding: 60px 32px;
    left: 0;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .about-vacancy__item {
    padding: 50px 20px;
    margin: 0 0 30px 0;
  }
}
.about-vacancy__item_none {
  margin: 0;
}
@media (max-width: 1023px) {
  .about-vacancy__item_none {
    margin: 0 0 60px 0;
  }
}
@media (max-width: 767px) {
  .about-vacancy__item_none {
    margin: 0 0 50px 0;
  }
}
.about-vacancy__item_none .about-vacancy__text {
  margin-bottom: 0;
}
.about-vacancy__title {
  margin-bottom: 15px;
  line-height: normal;
  font-size: 20px;
  color: #373E4B;
  font-family: 'OpenSans-Semibold';
  letter-spacing: 0.03em;
}
@media (max-width: 767px) {
  .about-vacancy__title {
    font-size: 18px;
    margin-bottom: 10px;
  }
}
.about-vacancy__title_small {
  line-height: 24px;
  font-size: 16px;
  letter-spacing: 0.05em;
}
.about-vacancy__text {
  max-width: 750px;
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.05em;
}
@media (max-width: 767px) {
  .about-vacancy__text {
    font-size: 14px;
    line-height: 20px;
  }
}
.about-vacancy__item-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 1023px) {
  .about-vacancy__item-row {
    display: block;
  }
}
.about-vacancy__item-col {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 48%;
          flex: 0 0 48%;
  max-width: 48%;
}
@media (max-width: 1023px) {
  .about-vacancy__item-col {
    max-width: none;
    margin: 0 0 30px 0;
  }
  .about-vacancy__item-col:last-child {
    margin-bottom: 0;
  }
}
.about-vacancy__ul {
  list-style-position: inside;
  line-height: 24px;
}
.about-vacancy__li {
  font-family: 'OpenSans-Regular';
  font-size: 16px;
  color: #666F81;
  position: relative;
  letter-spacing: 0.05em;
}
@media (max-width: 767px) {
  .about-vacancy__li {
    font-size: 14px;
    line-height: 20px;
  }
}
.about-vacancy__item-respond-btn {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 14px 30px;
  font-family: 'OpenSans-Regular';
  line-height: 24px;
  font-size: 16px;
  color: #FFFFFF;
  border-radius: 5px;
  margin-top: 30px;
}
@media (max-width: 767px) {
  .about-vacancy__item-respond-btn {
    font-size: 14px;
  }
}
.about-vacancy__item-respond-price {
  display: inline-block;
  vertical-align: middle;
  padding: 14px 30px;
  font-family: 'OpenSans-Semibold';
  line-height: 24px;
  font-size: 16px;
  color: #315191;
  border-radius: 5px;
  margin-top: 30px;
}
@media (max-width: 767px) {
  .about-vacancy__item-respond-price {
    display: block;
    padding: 0;
    font-size: 14px;
    line-height: 21px;
    margin-top: 20px;
  }
}
.about-vacancy__item-email-link {
  color: #3B7DFF;
  border-bottom: 1px solid #3B7DFF;
}
.catalog {
  padding: 80px 0 40px 0;
}
@media (max-width: 767px) {
  .catalog {
    padding: 30px 0 40px 0;
  }
}
.catalog__tabs {
  margin: 0 0 60px 0;
}
@media (max-width: 767px) {
  .catalog__tabs {
    margin: 0 0 20px 0;
  }
}
.catalog__content {
  font-size: 0;
}
.catalog__item {
  width: calc((100% - 40px) / 2);
  margin: 0 0 40px 0;
}
.catalog__item:nth-child(odd) {
  margin-right: 40px;
}
@media (max-width: 1023px) {
  .catalog__item:nth-child(odd) {
    margin-right: 0;
  }
}
@media (max-width: 1023px) {
  .catalog__item {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .catalog__item {
    margin: 0 0 30px 0;
  }
}
.catalog-products {
  padding: 20px 0 140px 0;
}
@media (max-width: 767px) {
  .catalog-products {
    padding: 10px 0 70px 0;
  }
}
.catalog-products__link {
  cursor: pointer;
  display: inline;
  color: #3B7DFF;
  border-bottom: 1px solid #3B7DFF;
  position: relative;
}
@media (max-width: 767px) {
  .catalog-products__link {
    font-size: 12px;
  }
}
.catalog-products__link::after {
  background-image: url("/assets/images/arrow-down.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  left: calc(100% + 15px);
  top: 2px;
  content: '';
  width: 10px;
  height: 20px;
}
@media (max-width: 767px) {
  .catalog-products__link::after {
    width: 7px;
    height: 14px;
    left: calc(100% + 8px);
  }
}
.catalog-products__content {
  margin: 20px 0 0 0;
  font-size: 0;
}
@media (max-width: 767px) {
  .catalog-products__content {
    margin: 0;
  }
}
.catalog-products__item {
  width: calc((100% - 40px * 2) / 3);
  margin: 40px 40px 0 0;
}
.catalog-products__item:nth-child(3n) {
  margin-right: 0;
}
@media (max-width: 1023px) {
  .catalog-products__item {
    width: calc((100% - 30px) / 2);
    margin-right: 0;
  }
  .catalog-products__item:nth-child(odd) {
    margin-right: 30px;
  }
}
@media (max-width: 767px) {
  .catalog-products__item {
    width: 100%;
    margin: 30px 40px 0 0;
    margin-right: 0 !important;
  }
}
.catalog-product-card {
  padding: 1.25rem 0 5rem 0;
  position: relative;
}
.catalog-product-card__breadcrumbs {
  margin: 0 0 60px 0;
}
@media (max-width: 767px) {
  .catalog-product-card__breadcrumbs {
    margin: 0 0 30px 0;
  }
}
.catalog-product-card__left {
  display: inline-block;
  vertical-align: top;
  width: 632px;
}
@media (max-width: 1023px) {
  .catalog-product-card__left {
    width: 100%;
    margin-bottom: 40px;
  }
}
.catalog-product-card__right {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 632px);
  padding: 0 0 0 40px;
  min-height: 477px;
  position: relative;
}
@media (max-width: 1023px) {
  .catalog-product-card__right {
    width: 100%;
    padding: 0;
    height: auto;
    min-height: auto;
  }
}
.catalog-product-card__right + .clear + .catalog-product-card__params {
  margin: 40px 0 0 0;
}
@media (max-width: 1023px) {
  .catalog-product-card__holder {
    position: relative !important;
    bottom: auto !important;
    width: 100% !important;
  }
}
.catalog-product-card__holder_sticky {
  position: fixed;
  top: 20px;
}
.catalog-product-card__container {
  margin: 20px 0 0 0;
}
.catalog-product-card__button {
  padding: 14px 30px;
}
.catalog-product-card__button-specifications {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  margin: 0 0 0 30px;
  font-size: 16px;
  line-height: 24px;
  color: #315191;
  border-bottom: 1px dashed rgba(49, 81, 145, 0.5);
}
@media (max-width: 767px) {
  .catalog-product-card__button-specifications {
    display: block;
    margin-left: 0;
    margin-top: 30px;
    width: 203px;
  }
}
.catalog-product-card__button-specifications:hover {
  color: #3B7DFF;
  border-bottom: 1px dashed rgba(49, 81, 145, 0);
}
.catalog-product-card__advantages {
  font-size: 0;
  margin: 80px 0;
}
@media (max-width: 767px) {
  .catalog-product-card__advantages {
    margin: 50px 0 70px 0;
  }
}
.catalog-product-card__advantages-item {
  display: inline-block;
  vertical-align: middle;
  width: calc((100% - 60px * 2) / 3);
}
.catalog-product-card__advantages-item:nth-child(3n+2) {
  margin: 0 60px;
}
@media (max-width: 1023px) {
  .catalog-product-card__advantages-item:nth-child(3n+2) {
    margin: 0;
  }
}
@media (max-width: 1023px) {
  .catalog-product-card__advantages-item {
    width: calc((100% - 60px) / 2);
  }
  .catalog-product-card__advantages-item:first-child {
    margin: 0 60px 40px 0;
  }
  .catalog-product-card__advantages-item:nth-child(2) {
    margin: 0 0 40px 0;
  }
}
@media (max-width: 767px) {
  .catalog-product-card__advantages-item {
    width: 100%;
    margin: 0;
  }
  .catalog-product-card__advantages-item:first-child {
    margin: 0 0 40px 0;
  }
}
.catalog-product-card__advantages-item-icon {
  display: inline-block;
  vertical-align: middle;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  width: 98px;
  height: 98px;
}
.catalog-product-card__advantages-item-text {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  line-height: 24px;
  padding: 0 0 0 20px;
  width: calc(100% - 98px);
}
.catalog-product-card__params-item {
  margin-bottom: 30px;
}
.catalog-product-card__params-item:last-child {
  margin-bottom: 0;
}
.catalog-product-card__params-item-head {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  font-size: 40px;
  line-height: 47px;
  font-family: 'Oswald-Medium';
  color: #315191;
  border-bottom: 1px solid #315191;
  position: relative;
}
@media (max-width: 767px) {
  .catalog-product-card__params-item-head {
    font-size: 25px;
    line-height: 30px;
  }
}
.catalog-product-card__params-item-head::before {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("/assets/images/arrow-down-2.svg");
  cursor: default;
  content: '';
  position: absolute;
  top: calc((100% - 12px) / 2 + 3px);
  left: calc(100% + 15px);
  width: 20px;
  height: 12px;
}
@media (max-width: 767px) {
  .catalog-product-card__params-item-head::before {
    width: 15px;
    height: 8px;
    left: calc(100% + 10px);
  }
}
.catalog-product-card__params-item-head:hover {
  border-bottom: 1px solid transparent;
}
.catalog-product-card__params-item-body {
  overflow: hidden;
  height: 0;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.catalog-product-card__params-item-holder {
  padding: 20px 0 0 0;
}
.catalog-product-card__params-item-content {
  padding: 0 0 20px 0;
}
.catalog-product-card__params-item-content table {
  min-width: 1112px;
}
@media (max-width: 767px) {
  .catalog-product-card__params-item-content table {
    min-width: 100%;
  }
}
@media (max-width: 767px) {
  .catalog-product-card__params-item-content table tr td {
    padding: 12px 15px !important;
  }
}
@media (max-width: 767px) {
  .catalog-product-card__params-item-content table tr td:last-child {
    width: 35%;
  }
}
.catalog-product-card__params-item-content .scrollbar-track-x {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 2px;
}
.catalog-product-card__params-item-content .scrollbar-track-x .scrollbar-thumb {
  background: #315191;
}
.contacts {
  padding: 20px 0 80px 0;
}
.contacts__breadcrumbs {
  margin: 0 0 60px 0;
}
.contacts__tabs {
  margin: 0 0 40px 0;
}
.contacts__title {
  margin-bottom: 30px;
}
.contacts__map {
  height: 500px;
  border-radius: 3px;
  background: whitesmoke;
  margin: 0 0 40px 0;
  position: relative;
}
@media (max-width: 767px) {
  .contacts__map {
    margin: 0 0 30px 0;
  }
}
.contacts__map-holder {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  overflow: hidden;
}
.contacts__map-coords {
  display: none;
}
.contacts__content {
  padding: 0 60px;
  font-size: 0;
}
@media (max-width: 1023px) {
  .contacts__content {
    padding: 0;
  }
}
.contacts__content_reset {
  padding: 0;
}
.contacts__content_flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-line-pack: start;
      align-content: flex-start;
}
@media (max-width: 1023px) {
  .contacts__content_flex {
    display: block;
  }
}
.contacts__content_flex .contacts__container {
  width: 100%;
}
.contacts__container {
  padding: 40px 0;
  border-bottom: 1px solid #9BA5B9;
}
.contacts__container:last-child {
  border-bottom: none;
}
.contacts__container_inline {
  display: inline-block;
  vertical-align: top;
  width: 50% !important;
  padding-right: 40px;
}
@media (max-width: 1023px) {
  .contacts__container_inline {
    width: 100% !important;
  }
}
.contacts__container_inline:last-child {
  border-bottom: 1px solid #9BA5B9;
}
.contacts__container_inline .contacts__block {
  width: 100%;
}
.contacts__container_w100 {
  width: 100% !important;
}
.contacts__container-city {
  margin-bottom: 30px;
  font-size: 20px;
  font-family: 'OpenSans-Semibold';
  color: #373E4B;
}
.contacts__container-content {
  font-size: 0;
}
.contacts__block {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  margin-bottom: 40px;
  padding-right: 40px;
}
@media (max-width: 1023px) {
  .contacts__block {
    width: 100%;
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .contacts__block {
    margin-bottom: 20px;
  }
}
@media (max-width: 1023px) {
  .contacts__block:nth-of-type(odd) {
    margin-right: 0;
  }
}
.contacts__block:nth-last-child(1),
.contacts__block:nth-last-child(2) {
  margin-bottom: 0;
}
@media (max-width: 1023px) {
  .contacts__block:nth-last-child(1),
  .contacts__block:nth-last-child(2) {
    margin-bottom: 30px;
  }
}
@media (max-width: 1023px) {
  .contacts__block:last-child {
    margin-bottom: 0;
  }
}
.contacts__block-logotype {
  height: 192px;
  width: 192px;
  max-width: 100%;
  max-height: 100%;
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: center;
      align-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-item-align: center;
      align-self: center;
}
.contacts__block-logotype-img {
  margin: auto;
  width: 100%;
}
.contacts__block-city {
  margin-bottom: 15px;
  font-size: 20px;
  font-family: 'OpenSans-Semibold';
  color: #373E4B;
}
@media (max-width: 767px) {
  .contacts__block-city {
    font-size: 18px;
  }
}
.contacts__block-company {
  cursor: pointer;
  display: inline;
  color: #315191;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 10px;
  font-family: 'OpenSans-Semibold';
  border-bottom: 1px solid #315191;
}
.contacts__block-company:hover {
  border-bottom: 1px solid transparent;
}
@media (max-width: 767px) {
  .contacts__block-company {
    font-size: 14px;
  }
}
.contacts__block-company + .contacts__item {
  margin-top: 10px;
}
.contacts__item {
  margin-bottom: 15px;
  font-size: 0;
}
@media (max-width: 1023px) {
  .contacts__item {
    margin-bottom: 12px;
  }
}
.contacts__item:last-child {
  margin-bottom: 0;
}
.contacts__item_address .contacts__item-icon {
  background-image: url("/assets/images/pin-gray.svg");
}
.contacts__item_phone .contacts__item-icon {
  background-image: url("/assets/images/phone-gray.svg");
}
.contacts__item_email .contacts__item-icon {
  background-image: url("/assets/images/email-gray.svg");
}
.contacts__item_site .contacts__item-icon {
  background-image: url("/assets/images/home-gray.svg");
}
.contacts__item-icon {
  display: inline-block;
  vertical-align: top;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  width: 20px;
  height: 20px;
  position: relative;
  bottom: -2px;
}
.contacts__item-content {
  display: inline-block;
  vertical-align: top;
  padding: 0 0 0 15px;
  width: calc(100% - 20px);
}
.contacts__item-content-elem {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  color: #666F81;
}
@media (max-width: 767px) {
  .contacts__item-content-elem {
    font-size: 14px;
  }
}
.contacts__item-content-elem:last-child {
  margin-bottom: 0;
}
.contacts__list {
  font-size: 0;
}
@media (max-width: 767px) {
  .contacts__list {
    margin: 0 0 70px 0;
  }
}
.contacts__list-item {
  display: inline-block;
  vertical-align: top;
  font-size: 0;
  width: calc((100% - 40px) / 2);
  margin-bottom: 40px;
}
@media (max-width: 1023px) {
  .contacts__list-item {
    width: 100%;
    margin-bottom: 30px;
  }
  .contacts__list-item:last-child {
    margin-bottom: 0;
  }
}
.contacts__list-item_block {
  display: block;
}
.contacts__list-item:nth-child(odd) {
  margin-right: 40px;
}
@media (max-width: 1023px) {
  .contacts__list-item:nth-child(odd) {
    margin-right: 0;
  }
}
.search {
  padding: 5rem 0 2.5rem 0;
}
.search__content {
  font-size: 0;
}
.search__item {
  width: calc((100% - 40px * 3) / 3);
  margin: 0 0 2.5rem 0;
}
.search__item:nth-child(3n+2) {
  margin: 0 2.5rem 2.5rem 2.5rem;
}
.search__no-result {
  margin: 0 0 2.5rem 0;
  font-size: 1.25rem;
}
.news {
  padding: 20px 0 40px 0;
}
.news__tabs {
  margin: 0 0 60px 0;
}
@media (max-width: 767px) {
  .news__tabs {
    margin: 0 0 0 0;
  }
}
.news__content {
  font-size: 0;
}
.news__content_type {
  margin: 60px 0 40px 0;
  position: relative;
  text-align: right;
}
@media (max-width: 767px) {
  .news__content_type {
    margin: 35px 0 30px 0;
  }
}
.news__item {
  width: calc((100% - 40px * 2) / 3);
  margin: 0 40px 40px 0;
}
.news__item:nth-child(3n) {
  margin-right: 0;
}
@media (max-width: 1023px) {
  .news__item {
    width: calc((100% - 30px) / 2);
    margin-right: 0;
  }
  .news__item:nth-child(odd) {
    margin-right: 30px;
  }
}
@media (max-width: 767px) {
  .news__item {
    width: 100%;
    margin: 30px 40px 0 0;
    margin-right: 0 !important;
  }
}
.news__item_video {
  cursor: pointer;
}
.news__container {
  margin: 0 0 40px 0;
}
@media (max-width: 767px) {
  .news__container {
    margin: 0 0 70px 0;
  }
}
@media (max-width: 767px) {
  .news__container:first-child {
    margin: 30px 0 70px 0;
  }
}
.news__container:last-child {
  margin: 0;
}
@media (max-width: 767px) {
  .news__container:last-child {
    margin: 30px 0 30px 0;
  }
}
.news__container-title {
  margin: 0 0 30px 0;
}
@media (max-width: 767px) {
  .news__container-title {
    margin: 0 0 20px 0;
  }
}
@media (max-width: 767px) {
  .news__container-content .news__item:first-child {
    margin: 0;
  }
}
.news__pagination {
  margin: 20px 0 40px 0;
}
@media (max-width: 767px) {
  .news__pagination {
    margin: 30px 0 30px 0;
  }
}
.news__left {
  display: inline-block;
  vertical-align: top;
  width: 632px;
  text-align: left;
  left: 0;
}
@media (max-width: 1023px) {
  .news__left {
    width: 100%;
    position: static !important;
    bottom: auto !important;
    left: 0 !important;
  }
}
.news__left_sticky {
  position: fixed;
  top: 20px;
  left: calc((100% - 1114px) / 2);
}
@media screen and (max-width: 1200px) {
  .news__left_sticky {
    left: 27px;
  }
}
.news__share {
  white-space: nowrap;
  font-size: 0;
  margin: 80px 0 0 0;
}
@media (max-width: 1023px) {
  .news__share {
    display: none;
  }
}
@media (max-width: 767px) {
  .news__share {
    margin: 70px 0 0 0;
    white-space: normal;
  }
}
.news__share_tablet {
  display: none;
}
@media (max-width: 1023px) {
  .news__share_tablet {
    display: block;
    text-align: left;
  }
}
.news__share-title {
  display: inline-block;
  vertical-align: middle;
  margin-right: 30px;
  font-size: 16px;
}
@media (max-width: 767px) {
  .news__share-title {
    display: block;
    margin-right: 0;
    margin-bottom: 20px;
  }
}
.news__share-content {
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
}
@media (max-width: 767px) {
  .news__share-content .likely {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-line-pack: start;
        align-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -ms-flex-item-align: start;
        align-self: flex-start;
  }
}
.news__share-item {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  font-size: 14px;
  color: white;
  padding: 5px 13px 8px 13px;
  border-radius: 3px;
  margin-right: 30px;
  font-family: 'OpenSans-Regular';
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media (max-width: 767px) {
  .news__share-item {
    margin-bottom: 20px;
    padding: 8px 15px 10px 15px;
  }
  .news__share-item:last-child {
    margin-bottom: 0;
  }
}
.news__share-item:hover {
  opacity: 0.7;
}
.news__share-item:last-child {
  margin-right: 0;
}
.news__share-item .likely__icon {
  margin-right: 15px;
  position: relative;
  bottom: -2px;
}
.news__share-item.likely__widget_vkontakte {
  background: #436EAB;
}
.news__share-item.likely__widget_facebook {
  background: #3B5786;
}
.news__share-item svg path {
  fill: white;
}
.news__right {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 632px);
  padding: 0 0 0 40px;
  text-align: left;
}
@media (max-width: 1023px) {
  .news__right {
    width: 100%;
    padding: 0;
    margin: 30px 0 0 0;
    text-align: left;
  }
}
@media (max-width: 767px) {
  .news__right {
    margin: 20px 0 0 0;
  }
}
.news__right_shift {
  margin-left: 632px;
}
.leasing__tabs {
  margin-bottom: 60px;
}
.leasing__title {
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .leasing__title {
    margin-bottom: 20px;
  }
}
.leasing__text {
  color: white;
}
.leasing__content {
  font-size: 0;
}
.leasing__content_type {
  font-size: 16px;
  margin: 70px 0 0 0;
}
.leasing__item {
  display: inline-block;
  vertical-align: middle;
  width: calc((100% - 40px * 2 - 1px) / 3);
  margin: 0 40px 40px 0;
}
@media (max-width: 1023px) {
  .leasing__item {
    width: calc((100% - 30px - 1px) / 2);
    margin-right: 0;
  }
  .leasing__item:nth-child(even) {
    margin-right: 30px;
  }
}
@media (max-width: 767px) {
  .leasing__item {
    width: 100%;
    margin: 0 0 20px 0;
  }
}
.leasing__item:nth-child(3n) {
  margin-right: 0;
}
.leasing__item:hover .leasing__item-title {
  color: #3B7DFF;
  border-bottom: 1px solid transparent;
}
.leasing__item-image {
  height: 193px;
  margin: 0 0 15px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -ms-flex-line-pack: center;
      align-content: center;
  -ms-flex-item-align: center;
      align-self: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.leasing__item-image-img {
  margin: auto 0;
  max-width: 200px;
}
.leasing__item-title {
  display: inline;
  font-size: 20px;
  color: #315191;
  border-bottom: 1px solid #315191;
}
@media (max-width: 767px) {
  .leasing__item-title {
    font-size: 18px;
  }
}
.leasing__card {
  margin: 80px 0 60px 0;
  font-size: 0;
}
@media (max-width: 767px) {
  .leasing__card {
    margin: 70px 0 50px 0;
  }
}
.leasing__card-image {
  display: inline-block;
  vertical-align: top;
  max-height: 192px;
  width: 344px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: center;
      align-content: center;
  -ms-flex-item-align: center;
      align-self: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.leasing__card-image_no-flex {
  display: inline-block;
}
@media (max-width: 767px) {
  .leasing__card-image {
    width: 100%;
  }
}
.leasing__card-image-img {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}
.leasing__card-content {
  display: inline-block;
  vertical-align: top;
  padding: 0 0 0 30px;
  width: calc(100% - 344px);
}
@media (max-width: 767px) {
  .leasing__card-content {
    padding: 0;
    margin: 20px 0 0 0;
    width: 100%;
  }
}
.leasing__card-title {
  font-size: 20px;
  margin: 0 0 15px 0;
  color: #373E4B;
  font-family: 'OpenSans-Semibold';
}
.repair {
  padding: 20px 0 80px 0 !important;
}
.repair__tabs {
  margin: 0 0 60px 0;
}
.repair__title {
  margin: 0 0 30px 0;
}
.repair__slider {
  margin: 0 0 60px 0;
  position: relative;
}
@media (max-width: 767px) {
  .repair__slider {
    margin: 0 0 50px 0;
  }
}
.repair__slider-navigation {
  position: absolute;
  bottom: calc((300px - 25px) / 2);
}
@media (max-width: 767px) {
  .repair__slider-navigation {
    bottom: calc((150px - 25px) / 2);
  }
}
.repair__slider-navigation_prev {
  right: calc(100% + 30px);
}
@media (max-width: 767px) {
  .repair__slider-navigation_prev {
    right: auto;
    left: 0;
    display: none;
  }
}
.repair__slider-navigation_next {
  left: calc(100% + 30px);
}
@media (max-width: 767px) {
  .repair__slider-navigation_next {
    left: auto;
    right: 0;
  }
}
.repair__slider-holder {
  overflow: hidden;
}
@media (max-width: 767px) {
  .repair__slider-holder {
    width: calc(100% - 20px);
  }
}
.repair__slider-wrapper {
  white-space: nowrap;
  font-size: 0;
}
.repair__slide {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
  font-size: 0;
  width: 100%;
}
.repair__block {
  display: inline-block;
  vertical-align: top;
  width: calc((100% - 40px) / 2);
  margin-right: 40px;
}
@media (max-width: 767px) {
  .repair__block {
    width: 100%;
  }
}
.repair__block:last-child {
  margin-right: 0;
}
.repair__block-title {
  margin: 0 0 15px 0;
  font-size: 20px;
  font-family: 'OpenSans-Semibold';
}
@media (max-width: 767px) {
  .repair__block-title {
    font-size: 18px;
    margin: 0 0 10px 0;
  }
}
.repair__block-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 300px;
  border-radius: 3px;
}
@media (max-width: 767px) {
  .repair__block-image {
    height: 150px;
  }
}
.partners__tabs {
  margin: 0 0 80px 0;
}
@media (max-width: 767px) {
  .partners__tabs {
    margin: 0 0 20px 0;
  }
}
.partners__container {
  margin: 0 0 20px 0;
}
@media (max-width: 767px) {
  .partners__container {
    margin: 0 0 50px 0;
  }
}
.partners__container:last-child {
  margin: 0;
}
.partners__container-title {
  margin: 0 0 30px 0;
}
.partners__container-content {
  font-size: 0;
}
.partners__block {
  display: inline-block;
  vertical-align: top;
  width: calc((100% - 40px) / 2);
  margin: 0 40px 40px 0;
}
@media (max-width: 767px) {
  .partners__block {
    width: 100%;
    margin: 0 0 20px 0;
  }
  .partners__block:last-child {
    margin: 0;
  }
}
.partners__block:nth-child(even) {
  margin: 0 0 40px 0;
}
@media (max-width: 767px) {
  .partners__block:nth-child(even) {
    margin: 0 0 20px 0;
  }
  .partners__block:nth-child(even):last-child {
    margin: 0;
  }
}
.partners__block-image {
  display: inline-block;
  vertical-align: top;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: center;
      align-content: center;
  -ms-flex-item-align: center;
      align-self: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 192px;
  height: 192px;
}
@media (max-width: 767px) {
  .partners__block-image {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    width: 100%;
  }
}
.partners__block-image-img {
  margin: auto;
  width: 100%;
}
@media (max-width: 767px) {
  .partners__block-image-img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    margin: auto 0;
  }
}
.partners__block-content {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 192px);
  padding: 0 0 0 30px;
}
@media (max-width: 767px) {
  .partners__block-content {
    width: 100%;
    padding: 0;
    margin: 10px 0 0 0;
  }
}
.partners__block-content-title {
  margin: 0 0 15px 0;
  font-size: 20px;
  font-family: 'OpenSans-Semibold';
}
@media (max-width: 767px) {
  .partners__block-content-title {
    font-size: 18px;
  }
}
.partners__block-content-item {
  margin: 0 0 14px 0;
}
.partners__block-content-item:last-child {
  margin: 0;
}
.partners__block-content-link {
  display: inline;
  font-size: 16px;
  color: #315191;
  border-bottom: 1px solid #315191;
}
@media (max-width: 767px) {
  .partners__block-content-link {
    font-size: 14px;
  }
}
body.compensate-for-scrollbar {
  overflow: hidden;
}
.fancybox-active {
  height: auto;
}
.fancybox-is-hidden {
  left: -9999px;
  margin: 0;
  position: absolute!important;
  top: -9999px;
  visibility: hidden;
}
.fancybox-container {
  -webkit-backface-visibility: hidden;
  height: 100%;
  left: 0;
  outline: none;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  width: 100%;
  z-index: 99992;
}
.fancybox-container * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.fancybox-bg,
.fancybox-inner,
.fancybox-outer,
.fancybox-stage {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.fancybox-outer {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}
.fancybox-bg {
  background: #1e1e1e;
  opacity: 0;
  -webkit-transition-duration: inherit;
       -o-transition-duration: inherit;
          transition-duration: inherit;
  -webkit-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71);
       -o-transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71);
          transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71);
}
.fancybox-is-open .fancybox-bg {
  opacity: 0.9;
  -webkit-transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
       -o-transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
          transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
}
.fancybox-caption,
.fancybox-infobar,
.fancybox-navigation .fancybox-button,
.fancybox-toolbar {
  direction: ltr;
  opacity: 0;
  position: absolute;
  -webkit-transition: opacity 0.25s ease, visibility 0s ease 0.25s;
  -o-transition: opacity 0.25s ease, visibility 0s ease 0.25s;
  transition: opacity 0.25s ease, visibility 0s ease 0.25s;
  visibility: hidden;
  z-index: 99997;
}
.fancybox-show-caption .fancybox-caption,
.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-nav .fancybox-navigation .fancybox-button,
.fancybox-show-toolbar .fancybox-toolbar {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease 0s, visibility 0s ease 0s;
  -o-transition: opacity 0.25s ease 0s, visibility 0s ease 0s;
  transition: opacity 0.25s ease 0s, visibility 0s ease 0s;
  visibility: visible;
}
.fancybox-infobar {
  color: #ccc;
  font-size: 13px;
  -webkit-font-smoothing: subpixel-antialiased;
  height: 44px;
  left: 0;
  line-height: 44px;
  min-width: 44px;
  mix-blend-mode: difference;
  padding: 0 10px;
  pointer-events: none;
  top: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.fancybox-toolbar {
  right: 0;
  top: 0;
}
.fancybox-stage {
  direction: ltr;
  overflow: visible;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  z-index: 99994;
}
.fancybox-is-open .fancybox-stage {
  overflow: hidden;
}
.fancybox-slide {
  -webkit-backface-visibility: hidden;
  display: none;
  height: 100%;
  left: 0;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: absolute;
  text-align: center;
  top: 0;
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: opacity,-webkit-transform;
  -o-transition-property: transform,opacity;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  white-space: normal;
  width: 100%;
  z-index: 99994;
}
.fancybox-slide:before {
  content: "";
  display: inline-block;
  font-size: 0;
  height: 100%;
  vertical-align: middle;
  width: 0;
}
.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--current,
.fancybox-slide--next,
.fancybox-slide--previous {
  display: block;
}
.fancybox-slide--image {
  overflow: hidden;
  padding: 44px 0;
}
.fancybox-slide--image:before {
  display: none;
}
.fancybox-slide--html {
  padding: 6px;
}
.fancybox-content {
  background: #fff;
  display: inline-block;
  margin: 0;
  max-width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: relative;
  text-align: left;
  vertical-align: middle;
}
.fancybox-slide--image .fancybox-content {
  -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
          animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  -webkit-backface-visibility: hidden;
  background: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: 0;
  max-width: none;
  overflow: visible;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-transform-origin: top left;
      -ms-transform-origin: top left;
          transform-origin: top left;
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: opacity,-webkit-transform;
  -o-transition-property: transform,opacity;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 99995;
}
.fancybox-can-zoomOut .fancybox-content {
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}
.fancybox-can-zoomIn .fancybox-content {
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}
.fancybox-can-pan .fancybox-content,
.fancybox-can-swipe .fancybox-content {
  cursor: -webkit-grab;
  cursor: grab;
}
.fancybox-is-grabbing .fancybox-content {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.fancybox-container [data-selectable=true] {
  cursor: text;
}
.fancybox-image,
.fancybox-spaceball {
  background: transparent;
  border: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}
.fancybox-spaceball {
  z-index: 1;
}
.fancybox-slide--iframe .fancybox-content,
.fancybox-slide--map .fancybox-content,
.fancybox-slide--pdf .fancybox-content,
.fancybox-slide--video .fancybox-content {
  height: 100%;
  overflow: visible;
  padding: 0;
  width: 100%;
}
.fancybox-slide--video .fancybox-content {
  background: #000;
}
.fancybox-slide--map .fancybox-content {
  background: #e5e3df;
}
.fancybox-slide--iframe .fancybox-content {
  background: #fff;
}
.fancybox-iframe,
.fancybox-video {
  background: transparent;
  border: 0;
  display: block;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
}
.fancybox-iframe {
  left: 0;
  position: absolute;
  top: 0;
}
.fancybox-error {
  background: #fff;
  cursor: default;
  max-width: 400px;
  padding: 40px;
  width: 100%;
}
.fancybox-error p {
  color: #444;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0;
}
.fancybox-button {
  background: rgba(30, 30, 30, 0.6);
  border: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  cursor: pointer;
  display: inline-block;
  height: 44px;
  margin: 0;
  padding: 10px;
  position: relative;
  -webkit-transition: color 0.2s;
  -o-transition: color 0.2s;
  transition: color 0.2s;
  vertical-align: top;
  visibility: inherit;
  width: 44px;
}
.fancybox-button,
.fancybox-button:link,
.fancybox-button:visited {
  color: #ccc;
}
.fancybox-button:hover {
  color: #fff;
}
.fancybox-button:focus {
  outline: none;
}
.fancybox-button.fancybox-focus {
  outline: 1px dotted;
}
.fancybox-button[disabled],
.fancybox-button[disabled]:hover {
  color: #888;
  cursor: default;
  outline: none;
}
.fancybox-button div {
  height: 100%;
}
.fancybox-button svg {
  display: block;
  height: 100%;
  overflow: visible;
  position: relative;
  width: 100%;
}
.fancybox-button svg path {
  fill: currentColor;
  stroke-width: 0;
}
.fancybox-button--fsenter svg:nth-child(2),
.fancybox-button--fsexit svg:first-child,
.fancybox-button--pause svg:first-child,
.fancybox-button--play svg:nth-child(2) {
  display: none;
}
.fancybox-progress {
  background: #ff5268;
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transform: scaleX(0);
      -ms-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: 0;
      -ms-transform-origin: 0;
          transform-origin: 0;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-timing-function: linear;
       -o-transition-timing-function: linear;
          transition-timing-function: linear;
  z-index: 99998;
}
.fancybox-close-small {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: #ccc;
  cursor: pointer;
  opacity: 0.8;
  padding: 8px;
  position: absolute;
  right: -12px;
  top: -44px;
  z-index: 401;
}
.fancybox-close-small:hover {
  color: #fff;
  opacity: 1;
}
.fancybox-slide--html .fancybox-close-small {
  color: currentColor;
  padding: 10px;
  right: 0;
  top: 0;
}
.fancybox-slide--image.fancybox-is-scaling .fancybox-content {
  overflow: hidden;
}
.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small {
  display: none;
}
.fancybox-navigation .fancybox-button {
  background-clip: content-box;
  height: 100px;
  opacity: 0;
  position: absolute;
  top: calc(50% - 50px);
  width: 70px;
}
.fancybox-navigation .fancybox-button div {
  padding: 7px;
}
.fancybox-navigation .fancybox-button--arrow_left {
  left: 0;
  left: env(safe-area-inset-left);
  padding: 31px 26px 31px 6px;
}
.fancybox-navigation .fancybox-button--arrow_right {
  padding: 31px 6px 31px 26px;
  right: 0;
  right: env(safe-area-inset-right);
}
.fancybox-caption {
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0, rgba(0, 0, 0, 0.85)), color-stop(50%, rgba(0, 0, 0, 0.3)), color-stop(65%, rgba(0, 0, 0, 0.15)), color-stop(75.5%, rgba(0, 0, 0, 0.075)), color-stop(82.85%, rgba(0, 0, 0, 0.037)), color-stop(88%, rgba(0, 0, 0, 0.019)), to(transparent));
  background: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.85) 0, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.15) 65%, rgba(0, 0, 0, 0.075) 75.5%, rgba(0, 0, 0, 0.037) 82.85%, rgba(0, 0, 0, 0.019) 88%, transparent);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.85) 0, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.15) 65%, rgba(0, 0, 0, 0.075) 75.5%, rgba(0, 0, 0, 0.037) 82.85%, rgba(0, 0, 0, 0.019) 88%, transparent);
  bottom: 0;
  color: #eee;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  line-height: 1.5;
  padding: 75px 44px 25px;
  pointer-events: none;
  right: 0;
  text-align: center;
  z-index: 99996;
}
@supports (padding:max(0px)) {
  .fancybox-caption {
    padding: 75px 44px 25px 44px;
  }
}
.fancybox-caption--separate {
  margin-top: -50px;
}
.fancybox-caption__body {
  max-height: 50vh;
  overflow: auto;
  pointer-events: all;
}
.fancybox-caption a,
.fancybox-caption a:link,
.fancybox-caption a:visited {
  color: #ccc;
  text-decoration: none;
}
.fancybox-caption a:hover {
  color: #fff;
  text-decoration: underline;
}
.fancybox-loading {
  -webkit-animation: a 1s linear infinite;
          animation: a 1s linear infinite;
  background: transparent;
  border: 4px solid #888;
  border-bottom-color: #fff;
  border-radius: 50%;
  height: 50px;
  left: 50%;
  margin: -25px 0 0 -25px;
  opacity: 0.7;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 50px;
  z-index: 99999;
}
@-webkit-keyframes a {
  to {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
  }
}
@keyframes a {
  to {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
  }
}
.fancybox-animated {
  -webkit-transition-timing-function: cubic-bezier(0, 0, 0.25, 1);
       -o-transition-timing-function: cubic-bezier(0, 0, 0.25, 1);
          transition-timing-function: cubic-bezier(0, 0, 0.25, 1);
}
.fancybox-fx-slide.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: translate3d(-100%, 0, 0);
          transform: translate3d(-100%, 0, 0);
}
.fancybox-fx-slide.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0);
}
.fancybox-fx-slide.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}
.fancybox-fx-fade.fancybox-slide--next,
.fancybox-fx-fade.fancybox-slide--previous {
  opacity: 0;
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
       -o-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
          transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1;
}
.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: scale3d(1.5, 1.5, 1.5);
          transform: scale3d(1.5, 1.5, 1.5);
}
.fancybox-fx-zoom-in-out.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
          transform: scale3d(0.5, 0.5, 0.5);
}
.fancybox-fx-zoom-in-out.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: scaleX(1);
      -ms-transform: scaleX(1);
          transform: scaleX(1);
}
.fancybox-fx-rotate.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: rotate(-1turn);
      -ms-transform: rotate(-1turn);
          transform: rotate(-1turn);
}
.fancybox-fx-rotate.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: rotate(1turn);
      -ms-transform: rotate(1turn);
          transform: rotate(1turn);
}
.fancybox-fx-rotate.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
          transform: rotate(0deg);
}
.fancybox-fx-circular.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
          transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
}
.fancybox-fx-circular.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
          transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
}
.fancybox-fx-circular.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: scaleX(1) translateZ(0);
          transform: scaleX(1) translateZ(0);
}
.fancybox-fx-tube.fancybox-slide--previous {
  -webkit-transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
          transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
}
.fancybox-fx-tube.fancybox-slide--next {
  -webkit-transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
          transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
}
.fancybox-fx-tube.fancybox-slide--current {
  -webkit-transform: translateZ(0) scale(1);
          transform: translateZ(0) scale(1);
}
@media (max-height: 576px) {
  .fancybox-slide {
    padding-left: 6px;
    padding-right: 6px;
  }
  .fancybox-slide--image {
    padding: 6px 0;
  }
  .fancybox-close-small {
    right: -6px;
  }
  .fancybox-slide--image .fancybox-close-small {
    background: #4e4e4e;
    color: #f2f4f6;
    height: 36px;
    opacity: 1;
    padding: 6px;
    right: 0;
    top: 0;
    width: 36px;
  }
  .fancybox-caption {
    padding-left: 12px;
    padding-right: 12px;
  }
  @supports (padding:max(0px)) {
    .fancybox-caption {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}
.fancybox-share {
  background: #f4f4f4;
  border-radius: 3px;
  max-width: 90%;
  padding: 30px;
  text-align: center;
}
.fancybox-share h1 {
  color: #222;
  font-size: 35px;
  font-weight: 700;
  margin: 0 0 20px;
}
.fancybox-share p {
  margin: 0;
  padding: 0;
}
.fancybox-share__button {
  border: 0;
  border-radius: 3px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  margin: 0 5px 10px;
  min-width: 130px;
  padding: 0 15px;
  text-decoration: none;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
}
.fancybox-share__button:link,
.fancybox-share__button:visited {
  color: #fff;
}
.fancybox-share__button:hover {
  text-decoration: none;
}
.fancybox-share__button--fb {
  background: #3b5998;
}
.fancybox-share__button--fb:hover {
  background: #344e86;
}
.fancybox-share__button--pt {
  background: #bd081d;
}
.fancybox-share__button--pt:hover {
  background: #aa0719;
}
.fancybox-share__button--tw {
  background: #1da1f2;
}
.fancybox-share__button--tw:hover {
  background: #0d95e8;
}
.fancybox-share__button svg {
  height: 25px;
  margin-right: 7px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 25px;
}
.fancybox-share__button svg path {
  fill: #fff;
}
.fancybox-share__input {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 0;
  color: #5d5b5b;
  font-size: 14px;
  margin: 10px 0 0;
  outline: none;
  padding: 10px 15px;
  width: 100%;
}
.fancybox-thumbs {
  background: #ddd;
  bottom: 0;
  display: none;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 2px 2px 4px;
  position: absolute;
  right: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  top: 0;
  width: 212px;
  z-index: 99995;
}
.fancybox-thumbs-x {
  overflow-x: auto;
  overflow-y: hidden;
}
.fancybox-show-thumbs .fancybox-thumbs {
  display: block;
}
.fancybox-show-thumbs .fancybox-inner {
  right: 212px;
}
.fancybox-thumbs__list {
  font-size: 0;
  height: 100%;
  list-style: none;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  position: relative;
  white-space: nowrap;
  width: 100%;
}
.fancybox-thumbs-x .fancybox-thumbs__list {
  overflow: hidden;
}
.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar {
  width: 7px;
}
.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  border-radius: 10px;
}
.fancybox-thumbs__list a {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: rgba(0, 0, 0, 0.1);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  float: left;
  height: 75px;
  margin: 2px;
  max-height: calc(100% - 8px);
  max-width: calc(50% - 4px);
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  width: 100px;
}
.fancybox-thumbs__list a:before {
  border: 6px solid #ff5268;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -o-transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 99991;
}
.fancybox-thumbs__list a:focus:before {
  opacity: 0.5;
}
.fancybox-thumbs__list a.fancybox-thumbs-active:before {
  opacity: 1;
}
@media (max-width: 576px) {
  .fancybox-thumbs {
    width: 110px;
  }
  .fancybox-show-thumbs .fancybox-inner {
    right: 110px;
  }
  .fancybox-thumbs__list a {
    max-width: calc(100% - 10px);
  }
}
.ymaps-2-1-73-balloon .ymaps-2-1-73-balloon__content > ymaps {
  width: 300px !important;
  height: auto !important;
}
.ym__item {
  font-size: 15px;
  font-family: 'OpenSans-Regular';
  margin-bottom: 5px;
}
.ym__item_name {
  font-family: 'OpenSans-Semibold';
  margin-bottom: 8px;
  font-size: 17px;
}
.ym__item a {
  color: #315191;
  font-size: 15px !important;
}
.ym__item a:hover {
  text-decoration: underline;
}
html,
body {
  font-size: 16px;
  font-weight: 300;
  color: #666F81;
  font-family: 'OpenSans-Regular';
  cursor: default;
}
body {
  overflow-x: hidden;
  overflow-y: auto;
}
body.no-scroll {
  overflow: hidden;
  height: 100%;
}
.inner {
  width: 100%;
  max-width: 1114px;
  margin: auto;
}
@media screen and (max-width: 1200px) {
  .inner {
    padding: 0 27px;
    max-width: none;
  }
}
@media (max-width: 1023px) {
  .inner {
    padding: 0 27px;
  }
}
@media (max-width: 767px) {
  .inner {
    padding: 0 20px;
  }
}
.inner_politics {
  margin-top: 82px;
  padding-top: 60px;
  padding-bottom: 60px;
  border-top: 1px solid whitesmoke;
}
@media (max-width: 1023px) {
  .inner_politics {
    margin-top: 128px;
  }
}
@media (max-width: 767px) {
  .inner_politics {
    margin-top: 0;
    border-top: none;
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.content {
  overflow: hidden;
  margin-top: 53px;
}
@media (max-width: 767px) {
  .content {
    margin-top: 86px;
  }
}
.holder {
  padding: 20px 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
}
h1 {
  line-height: 53px;
  font-size: 45px;
  letter-spacing: 0.03em;
  font-family: 'Oswald-Medium';
  color: #373E4B;
}
@media (max-width: 767px) {
  h1 {
    font-size: 30px;
    line-height: 36px;
  }
}
h2 {
  line-height: 47px;
  font-size: 40px;
  letter-spacing: 0.03em;
  font-family: 'Oswald-Medium';
  color: #373E4B;
}
@media (max-width: 767px) {
  h2 {
    font-size: 25px;
    line-height: 30px;
  }
}
h3 {
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.03em;
  color: #373E4B;
}
.text {
  font-size: 16px;
}
@media (max-width: 767px) {
  .text {
    font-size: 14px;
    line-height: 20px;
  }
}
.text h1,
.text h2 {
  margin: 0 0 30px 0;
}
.text h3,
.text h4,
.text h5 {
  margin: 0 0 15px 0;
}
@media (max-width: 767px) {
  .text h3,
  .text h4,
  .text h5 {
    font-size: 18px;
    margin: 0 0 10px 0;
  }
}
.text p {
  margin-bottom: 12px;
  line-height: 24px;
}
@media (max-width: 767px) {
  .text p {
    margin: 0 0 10px 0;
  }
}
.text p:last-child {
  margin-bottom: 0;
}
.text ul {
  margin: 0 0 30px 0;
  list-style-position: inside;
}
.text ul:last-child {
  margin: 0;
}
.text ul li {
  margin: 0 0 15px 0;
}
.text ul li:last-child {
  margin: 0;
}
.text table {
  border: none;
  width: 100% !important;
  color: #666F81;
  font-size: 16px;
  line-height: 24px;
}
.text table tr {
  border: none;
}
.text table tr:nth-child(odd) {
  background: #9BA5B9;
  color: white;
}
.text table tr td {
  padding: 12px 20px;
  width: 50%;
  border-right: 2px solid white;
}
.text table tr td:last-child {
  border-right: none;
}
.form-error {
  font-size: 12px;
  list-style: none;
  color: rgba(231, 76, 60, 0.5);
}
.parsley-error {
  background: rgba(231, 76, 60, 0.1);
}
.some-image {
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: contain; object-position: center;';
}
.object-fit-cover {
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center;';
}
.object-fit-contain {
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: contain; object-position: center;';
}
